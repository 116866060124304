.header-banner-old {
    background: $main-color;
    height: 40px;

    .container {
      display: flex;
      justify-content: space-between;

      @media all and (max-width: $mobile-width) {
        justify-content: flex-end;
      }

      .header-banner-left,
      .header-banner-right {
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        p {
          color: white;
          margin: 0;
        }
      }

      .header-banner-left {
        @media all and (max-width: $mobile-width) {
          display: none;
        }

        p {
          @include font-kremlin();
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 1.4em;
          font-style: italic;
        }
      }

      .header-banner-right {
        @media all and (max-width: $small-mobile-width) {
          & > p,
          & > .btn-container {
            transition: opacity 0.3s ease 0.3s;
          }
          &.active {
            & > p,
            & > .btn-container {
              transition: opacity 0s ease;
              visibility: hidden;
              opacity: 0;
            }
          }
        }
        p {
          font-size: 1.4em;
          margin: 0 15px 0;

          @media all and (max-width: $small-mobile-width) {
            font-size: 1.1em;
          }

          a {
            color: #494948;
            &:hover {
              color: white;
            }
          }
        }

        .btn-container {
          margin: 0 5px 0;
        }

        .search-container {
          form {
            display: flex;
            background: #f6b460;
            border: 1px solid #fcdcb2;
            border-radius: 5px;
            &.active {
              input {
                width: 180px;
                padding: 0 10px;

                @media all and (max-width: $mobile-width) {
                  width: 140px;
                }
              }
            }

            input {
              border: none;
              background: none;
              margin: 0;
              font-size: 1.4em;
              color: white;
              @include placeholder(white);
              box-shadow: none;
              height: 30px;
              width: 0;
              padding: 0;
              transition: all 0.3s ease;
            }
            button {
              width: 30px;
              height: 30px;
              opacity: 0.41;
              transition: all 0.3s ease;
              padding: 6px;
              cursor: pointer;
              &:hover,
              &:focus {
                opacity: 1;
              }
              img {
                width: 18px;
                height: auto;
              }
            }
          }
        }
      }

      .btn {
        display: inline-block;
        font-size: 1.4em;
        color: white;
        background: #f6b460;
        border: 1px solid #fcdcb2;
        border-radius: 5px;
        padding: 8px 15px;
        line-height: 1em;

        &:hover,
        &:focus {
          color: $main-color;
          background: white;
          border: 1px solid white;
        }
      }
    }
  }

  .header-navigation-old {
    position: relative;
    background: white;
    height: 100px;
    transition: all 0.3s ease, transform 0.5s ease;

    @media all and (max-width: $tablet-width) {
      &.menu-open {
        transform: translateX(-$mobile-menu-width);
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .logo-container {
      display: flex;
      align-items: center;

      h1 {
        display: none;
      }
      a {
        display: block;
        padding: 17px 0 19px;
        img {
          width: 143px;
          transition: all 0.3s ease;
        }
      }

      p {
        display: none;
        color: $main-color;
        margin: 0 0 0 15px;
        @include font-kremlin;
        font-size: 1.2em;
        text-transform: uppercase;

        @media all and (max-width: $mobile-width) {
          display: block;
        }

        @media all and (max-width: $small-mobile-width) {
          font-size: 1.1em;
        }
      }
    }

    .navigation {
      display: flex;
      // transition: all 0.5s ease;

      @media all and (max-width: $tablet-width) {
        display: block;
        position: fixed;
        border-left: 1px solid $main-color;
        top: 0;
        right: 0;
        width: $mobile-menu-width;
        height: 100vh;
        background: white;
        transform: translateX($mobile-menu-width);
      }

      a {
        display: block;
        color: #58595b;
        font-size: 1.6em;
        line-height: 1em;
        padding: 42px 20px;

        &:last-child {
          padding-right: 0;
        }
        &:hover,
        &:focus,
        &.active,
        &.hover-active {
          color: $main-color;
        }

        @media all and (max-width: $tablet-width) {
          color: $main-color;
          border-bottom: 1px solid $main-color;
          padding: 17.8px 15px !important;

          &:hover,
          &:focus,
          &.active,
          &.hover-active {
            background: lighten($main-color, 28%);
          }
        }

        &.header-menu {
          @media all and (max-width: $tablet-width) {
            position: relative;
            padding-right: 30px !important;
            &:after {
              position: absolute;
              top: 17px;
              right: 10px;
              content: ">";
            }
          }
        }
      }
    }

    #hamburger {
      position: absolute;
      right: 15px;

      span {
        background: $main-color;
      }
      @media all and (max-width: $tablet-width) {
        display: inline-block;
      }
    }

    &.scrolled {
      @include smallHeader();
    }

    @media all and (max-width: $tablet-width) {
      @include smallHeader();
    }
  }