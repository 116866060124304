@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/GothamHTF-Light.woff2") format("woff2"),
    url("../fonts/gotham/GothamHTF-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/GothamHTF-Book.woff2") format("woff2"),
    url("../fonts/gotham/GothamHTF-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/GothamHTF-Medium.woff2") format("woff2"),
    url("../fonts/gotham/GothamHTF-Medium.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("../fonts/gotham/GothamHTF-Bold.woff2") format("woff2"),
    url("../fonts/gotham/GothamHTF-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "ITC Avant Garde Gothic";
  src: url("../fonts/gotham/ITCAvantGardeGothicBold.woff") format("woff");
  // font-weight: 700;
  font-style: normal;
}
