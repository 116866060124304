@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;0,900;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Herr+Von+Muellerhoff&display=swap');

// Font Variables
$roboto: "Roboto", sans-serif;
$itc-gothic: "ITC Avant Garde Gothic";
$gotham: "Gotham";
$header-height: 100px;
$header-small-height: 52px;
$mobile-menu-width: 240px;
$mobile-sub-menu-width: 239px;

.everyday-color {
  color: $main-color;
}

.essential-color {
  color: $essential-color;
}

html,
body {
  @media all and (max-width: $tablet-width) {
    &.menu-open {
      overflow: hidden;
    }
  }

  &.admin-bar {
    .sticker-ad {
      margin-top: 32px;

      @media all and (max-width: 782px) {
        margin-top: 46px;
      }
    }
    #header {
      padding-top: 32px;

      @media all and (max-width: 782px) {
        padding-top: 46px;
      }
    }

    #header-menu {
      top: $header-height + 32px;

      &.scrolled {
        top: $header-small-height + 32px;
      }

      @media all and (max-width: $tablet-width) {
        top: 0;

        &.scrolled {
          top: 0;
        }

        .header-menu-container {
          top: 32px + 40px;

          &.scrolled {
            top: 32px + 40px;
          }
        }
      }

      @media all and (max-width: 782px) {
        .header-menu-container {
          top: 46px + 40px;
          &.scrolled {
            top: 46px + 40px;
          }
        }
      }
    }
  }

  #wpadminbar {
    @media all and (max-width: 782px) {
      position: fixed;
    }

    @media all and (max-width: 390px) {
      #wp-admin-bar-my-account {
        display: none;
      }
    }
  }
}

.animate {
  opacity: 0;
  transform: translateY(-20px);
  transition: all 0.5s ease;

  &.animate-ready {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(247, 172, 77, 0.8);
  }
  70% {
    -webkit-box-shadow: 0 0 0 6px rgba(247, 172, 77, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(247, 172, 77, 0);
  }
}

@keyframes mouseWheel {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: 0px 20px;
  }
}

@mixin line() {
  content: "";
  display: block;
  height: 2px;
  width: 100%;
  background: url("/wp-content/themes/aston/assets/img/bg-line.png") repeat;
  position: absolute;
  top: 0;
  left: 0;
}

@mixin contactForm() {
  margin: auto;
  max-width: 580px;
  padding: 40px 0 0;

  .form-flex {
    display: flex;
    @media all and (max-width: $small-mobile-width) {
      flex-wrap: wrap;
    }

    .left,
    .right {
      width: 50%;

      @media all and (max-width: $small-mobile-width) {
        width: 100%;
      }
    }

    .left {
      padding-right: 9px;

      @media all and (max-width: $small-mobile-width) {
        padding-right: 0;
      }
    }

    .right {
      padding-left: 9px;

      @media all and (max-width: $small-mobile-width) {
        padding-left: 0;
      }
    }
  }

  .checkbox-container label {
    justify-content: center;
  }

  button {
    width: 100%;
    font-size: 1.3em;
    margin: 0 0 20px;
  }
}

@mixin smallHeader() {
  height: 52px;
  .logo-container {
    a {
      float: left;
      text-align: left;
      img {
        width: 80px;
        @media all and (max-width: 400px) {
          width: 60px;
          left: 15px;
        }
      }
    }
  }

  .navigation {
    a {
      padding: 18px 20px;
    }

    a.header-contact-menu {
      margin-top: 8px;
      padding: 0;
      height: 34px;
      line-height: 34px;
      margin-left: 0;
      @media all and (max-width: $tablet-width) {
        float: none;
        margin: 10px auto;
        color: #FFF;
        width: 100%;
        padding: 0 0 0 14px !important;
        background-color: transparent;
        border-radius: 0;
        text-align: left;
        &:hover,
        &:focus,
        &.active,
        &.hover-active {
          background: #ffa018;
          color: white;
        }
      }
    }
  }
}

.two-column {
  display: flex;
  .left,
  .right {
    width: 50%;
  }
}

.container {
  width: 1200px;
  max-width: 100%;
  padding: 0 15px;
  margin: auto;
  box-sizing: border-box;
}

.basic-banner {
  background: $gray-bg-color;
  padding: 25px 0;
  text-align: center;

  h2 {
    @include heading-5em();
  }

  h3 {
    color: $main-color;
    font-weight: 300;
    font-size: 3.5em;
    letter-spacing: 0;
    margin: 0 auto 5px;
    line-height: 1.1em;
    max-width: 850px;

    @media all and (max-width: $mobile-width) {
      font-size: 3em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 2.5em;
    }
  }

  h4 {
    color: $main-color;
    font-weight: 300;
    font-size: 3em;
    letter-spacing:0;
    margin: 0 auto 5px;
    line-height: 1.1em;
    max-width: 850px;

    @media all and (max-width: $mobile-width) {
      font-size: 2.5em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 2em;
    }
  }

  h5 {
    color: $main-color;
    font-weight: 300;
    font-size: 2.2em;
    line-height: 1.1em;
    letter-spacing: 0;

    @media all and (max-width: $mobile-width) {
      font-size: 2em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 1.8em;
    }
  }

  p {
    line-height: 1.3em;
    font-size: 1.5em;
    max-width: 1000px;
    margin: 0 auto 10px;

    @media all and (max-width: $mobile-width) {
      font-size: 1.3em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 1.2em;
    }
  }

  a.btn-orange {
    margin-top: 50px;

    @media all and (max-width: $mobile-width) {
      margin-top: 30px;
    }
  }
}

.basic-form {
  .wpcf7-response-output {
    border: none !important;
    font-size: 1.7em;
    padding: 0;
    margin: 20px 0 0;
  }
  .input-container {
    margin: 0 0 20px;
  }

  input,
  select {
    margin: 0;
    background: $form-input-bg-color;
    border: 1px solid $form-input-border-color;
    color: $form-input-text-color;
    @include placeholder($form-input-text-color);
    border-radius: 4px;
    font-size: 1.3em;
    font-weight: normal;
    box-shadow: none;
    padding: 10px 16px;
    height: 42px;

    &:hover,
    &:focus,
    &:active {
      background: $form-input-bg-color;
      border: 1px solid $form-input-border-color;
      color: $form-input-text-color;
      @include placeholder($form-input-text-color);
    }
  }

  label {
    font-size: 1.3em;
    font-weight: normal;
    color: $form-input-text-color;
  }

  .checkbox-container {
    label {
      display: flex;
      align-items: center;
      cursor: pointer;

      &:before {
        display: block;
        content: "";
        min-width: 20px;
        min-height: 20px;
        background: $form-input-bg-color;
        border: 1px solid $form-input-border-color;
        border-radius: 4px;
        margin: 0 8px 0 0;
      }
    }

    input[type="checkbox"] {
      display: none;

      &:hover + label:before {
        background: #ffa018;
        color: white;
      }

      &:checked + label:before {
        background: $form-input-bg-color url("/wp-content/themes/aston/assets/img/icon-check@2x.png")
          center center no-repeat !important;
        background-size: contain !important;
      }
    }
  }
}

.checkbox-text-container,
.radio-text-container {
  width: 30px;
  height: 30px;
  background: $form-input-bg-color;
  border: 1px solid $form-input-border-color;
  border-radius: 4px;
  margin: 0 8px 0 0;
  padding: 4px;

  label {
    display: block;
    width: 20px;
    height: 20px;
    color: $text-color;
    border-radius: 4px;
    padding: 3px 0;
    line-height: 1em;
    text-align: center;
    margin: 0;
  }

  input[type="checkbox"],
  input[type="radio"] {
    display: none;

    &:hover + label,
    &:checked + label {
      background: $main-color;
      color: white;
    }
  }
}

.basic-content {
  padding: 40px 0 100px;

  p {
    color: $basic-text-color;
  }
}

.two-content-container,
.two-image-container {
  display: flex;
  padding: 50px 0;

  @media all and (max-width: $small-mobile-width) {
    flex-wrap: wrap;
    padding: 20px 0;
  }

  .left,
  .right {
    width: 50%;
    padding: 25px;

    @media all and (max-width: $small-mobile-width) {
      width: 100%;
      padding: 15px;
    }
  }
}

.btn-orange,
.btn-light-gray,
.btn-green {
  display: inline-block;
  font-size: 1.8em;
  line-height: 1em;
  color: white;
  background: $main-color;
  border-radius: 5px;
  padding: 15px 40px;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    background: white;
    color: $main-color;
  }

  &.btn-medium {
    padding: 8px 35px;
  }
  &.btn-small {
    font-size: 1.2em;
    padding: 8px 20px;
  }

  &.btn-medium {
    padding: 8px 20px;
  }

  &.btn-light-gray {
    background: #c8c6c3;

    &:hover,
    &:focus {
      color: white;
      background: darken(#c8c6c3, 10%);
    }
  }

  &.btn-green {
    background: $essential-color;

    &:hover,
    &:focus {
      color: white;
      background: darken($essential-color, 10%);
    }
  }

  @media all and (max-width: $tablet-width) {
    font-size: 1.4em;
    padding: 12px 30px;

    &.btn-medium {
      padding: 6px 20px;
    }
    // &.btn-small {
    //   font-size: 1em;
    //   padding: 6px 15px;
    // }

    &.btn-medium {
      padding: 6px 15px;
      margin: 20px;
    }
  }
}
a.btn-orange.btn-medium.floor-plans{
  font-size: 1.3em;
  
  padding: 12px 30px;
  font-family: gotham,sans-serif;
}
.floor-plans{
  font-size: 1.5em;
 
  padding: 12px 30px;
  background-color: #ffa018 !important;
  border: 1px solid #ffa018;
  &:hover{
    background-color: #FFF  !important;
  }
}
#newsletter-form {
  clear: both;
  display: flex;
  flex-wrap: wrap;
  max-width: 775px;
  margin: 25px auto 0;

  .ajax-loader {
    display: none;
  }

  .wpcf7-response-output {
    flex: 1;
    border: none !important;
    margin: 0;
    padding: 0;
    font-size: 1.4em;
  }

  @media all and (max-width: $small-mobile-width) {
    flex-wrap: wrap;
    justify-content: center;
  }

  input {
    width: 50%;
    height: 42px;
    font-size: 1.4em;
  }

  .newsletter-email {
    border-radius: 5px 0 0 5px;
    border: 1px solid $heading-color;
    background: none;
    box-shadow: none;
    color: $heading-color;
    @include placeholder($heading-color);
    padding: 0 20px;
    float: left;
    @media all and (max-width: $large-desktop-width) {
      font-size: 18px;
    }

    @media all and (max-width: $mobile-width) {
      width: 100%;
      border-radius: 0;
      text-align: center;
      margin-bottom: 0;
    }
  }
  .btn-orange {
    border-radius: 0 5px 5px 0;
    padding: 10px 40px;
    transition: all 0.3s ease;
    border: 1px solid $heading-color;
    background-color: $heading-color;
    &:hover,
    &:focus {
      background: #ffa018;
      border: 1px solid $heading-color;
      color: #FFF;
    }

    @media all and (max-width: $mobile-width) {
      border-radius: 0;
      width: 100%;
    }
  }
}

.main {
  transition: all 0.5s ease;
  transform: translateX(0);

  @media all and (max-width: $tablet-width) {
    &.menu-open {
      transform: translateX(-$mobile-menu-width);
    }
  }
}

#header {
  position: fixed;
  z-index: 102;
  left: 0;
  top: 0;
  width: 100%;

  &.scrolled {
    box-shadow: 0 0 10px gray;
  }
}

.header-banner {
  background: $hero-heading-color;
  height: 40px;

  .container {
    display: flex;
    justify-content: space-between;

    @media all and (max-width: $mobile-width) {
      justify-content: flex-end;
    }

    .header-banner-left,
    .header-banner-right {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      p {
        color: white;
        margin: 0;
      }
    }

    .header-banner-left {
      @media all and (max-width: $mobile-width) {
        display: none;
      }

      p {
        @include font-kremlin();
        text-transform: uppercase;
        letter-spacing: 0;
        font-size: 1.4em;
        font-style: italic;
      }
    }

    .header-banner-right {
      @media all and (max-width: $small-mobile-width) {
        & > p,
        & > .btn-container {
          transition: opacity 0.3s ease 0.3s;
        }
        &.active {
          & > p,
          & > .btn-container {
            transition: opacity 0s ease;
            visibility: hidden;
            opacity: 0;
          }
        }
      }
      p {
        font-size: 1.4em;
        margin: 0 15px 0;

        @media all and (max-width: $small-mobile-width) {
          font-size: 1.1em;
        }

        a {
          color: $main-color;
          &:hover {
            color: white;
          }
        }
      }

      .btn-container {
        margin: 0 5px 0;
      }

      .search-container {
        form {
          display: flex;
          background: #595959;
          border: 1px solid #7a7a78;
          border-radius: 5px;
          &.active {
            input {
              width: 180px;
              padding: 0 10px;

              @media all and (max-width: $mobile-width) {
                width: 140px;
              }
            }
          }

          input {
            border: none;
            background: none;
            margin: 0;
            font-size: 1.4em;
            color: white;
            @include placeholder(white);
            box-shadow: none;
            height: 30px;
            width: 0;
            padding: 0;
            transition: all 0.3s ease;
          }
          button {
            width: 30px;
            height: 30px;
            opacity: 0.41;
            transition: all 0.3s ease;
            padding: 6px;
            cursor: pointer;
            &:hover,
            &:focus {
              opacity: 1;
            }
            img {
              width: 18px;
              height: auto;
            }
          }
        }
      }
    }

    .btn {
      display: inline-block;
      font-size: 1.4em;
      color: white;
      background: #f6b460;
      border: 1px solid #fcdcb2;
      border-radius: 5px;
      padding: 8px 15px;
      line-height: 1em;

      &:hover,
      &:focus {
        color: $main-color;
        background: white;
        border: 1px solid white;
      }
    }

    .btn-gray {
      display: inline-block;
      font-size: 1.4em;
      color: white;
      background: #595959;
      border: 1px solid #7a7a78;
      border-radius: 5px;
      padding: 8px 15px;
      line-height: 1em;

      &:hover,
      &:focus {
        color: white;
        background: $main-color;
        border: 1px solid $main-color;
      }
    }
  }
}

.header-navigation {
  position: relative;
  background: $main-color;
  background: $heading-color;
  height: 100px;
  transition: all 0.3s ease, transform 0.5s ease;

  @media all and (max-width: $tablet-width) {
    &.menu-open {
      transform: translateX(-$mobile-menu-width);
    }
  }

  .container {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    position: relative;
    @media all and (max-width: $tablet-width) {
      display: block;
      text-align: center;
    }
  }
  .logo-container {
    display: flex;
    align-items: center;
    @media all and (max-width: $tablet-width) {
      display: block;
      width: calc(100% - 100px);
      float: left;
    }
    @media all and (max-width: 400px) {
      width: calc(100% - 60px);
    }
    h1 {
      display: none;
    }
    a {
      display: block;
      padding: 0;
      width: 150px;
      @media all and (max-width: $tablet-width) {
        width: 100px;
      }
      @media all and (max-width: 400px) {
        width: 60px;
      }
      img {
        width: 150px;
        transition: all 0.3s ease;
        position: absolute;
        top: 0;
      }
    }

    p {
      display: none;
      color: $main-color;
      margin: 0px;
      @media all and (max-width: $tablet-width) {
        display: inline-block;
      }
      img{
        height: 40px;
        width: auto;
        margin-top: 6px;
        padding-bottom: 0px;
        @media all and (max-width: $super-small-mobile-width) {
          height: 30px;
          margin-top: 12px;
        }
    }
    
    }
  }

  .navigation {
    display: inline-block;
    width: 100%;
    // transition: all 0.5s ease;

    @media all and (max-width: $tablet-width) {
      display: block;
      position: fixed;
      border-left: 1px solid $main-color;
      top: 0;
      right: 0;
      width: $mobile-menu-width;
      height: 100vh;
      background: $heading-color;
      transform: translateX($mobile-menu-width);
      text-align: left;
    }

    .header-contact-menu {
      width: 128px;
      height: 45px;
      background: #ffa018;
      border-radius: 10px;
      padding: 0;
      line-height: 45px;
      text-align: center;
      float: right;
      margin-top: 27px;
      margin-left: 20px;
    }
  }

    a {
      display: inline-block;
      color: white;
      font-size: 1.6em;
      line-height: 1em;
      padding: 42px 20px;
      
      &:last-child {
        padding-right: 0;
        float: right;
        &:hover,
        &:focus
        {
          color:$main-color;
        }
      }
      // &:hover,
      // &:focus{
      //   color:$main-color;
      // }
      
      // &:hover,
      // &:focus,
      // &.active,
      // &.hover-active {
      //   color: $hero-heading-color;
      // }

      @media all and (max-width: $tablet-width) {
        color: #fff;
        // border-bottom: 1px solid $main-color;
        padding: 17.8px 15px !important;
        width: 100%;

        &:hover,
        &:focus,
        &.active,
        &.hover-active {
          background: #ffa018;
          color: white;
        }
      }

      a {
        &:hover,
        &:focus,
        &.active,
        &.hover-active {
          color: #CCC;
        }
      }

      &.header-menu {
        @media all and (max-width: $tablet-width) {
          position: relative;
          padding-right: 30px !important;
          &:after {
            position: absolute;
            top: 17px;
            right: 10px;
            content: ">";
          }
        }
      }
  
    &.header-menu:after {
      content:"";
      border: solid #fff;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: relative;
      left: 10px;
      top: -2px;
    }

  }

  #hamburger {
    position: absolute;
    right: 15px;
    width: 100px;
    color: #ffffff;
    font-size: 20px;
    span {
      margin: 5px 0 5px 0;
      background: white;
      width:30px;
      left: auto;
      right: 0;
    }

    @media all and (max-width: $tablet-width) {
      display: inline-block;
      text-align: left;
      margin-top: 10px;
    }
    @media all and (max-width: 400px) {
      width: 40px;
      color: transparent;
    }
  }

  &.scrolled {
    @include smallHeader();
  }

  @media all and (max-width: $tablet-width) {
    @include smallHeader();
  }
}

#header-menu {
  display: none;
  position: fixed;
  z-index: 101;
  left: 0;
  top: $header-height;
  top: 100px;
  width: 100%;
  transition: top 0.3s ease;
  // max-height: 259px;

  @media all and (max-width: $tablet-width) {
    z-index: 105;
  }

  &:before {
    z-index: -1;
    position: absolute;
    content: "";
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    box-shadow: 0 0 10px black;
  }

  &.scrolled {
    top: $header-small-height;
    // top: 52px;

    .header-menu-container {
      &#menu-your-home-designs {
        .menu-content {
          padding: 0 0 25px 120px;
        }
      }
    }
  }

  @media all and (max-width: $tablet-width) {
    display: block !important;
    top: 0;
    right: 0;
    left: auto;
    transform: translateX($mobile-menu-width);
    width: $mobile-sub-menu-width;
    height: 100vh;
    max-height: none;
    transition: none;

    &.scrolled {
      top: 0;
      display: block !important;
    }
  }

  .header-menu-container {
    display: none;
    background: $main-color;
    background: $heading-color;
    border-top: 1px solid #333;

    .container {
      display: flex;
    }

    &#menu-seek-learn-know {
      .container {
        width: 1420px;
      }
    }

    @media all and (max-width: $tablet-width) {
      display: block !important;
      position: fixed;
      top: 40px;
      right: 0;
      height: 100%;
      padding-bottom: 40px;
      width: $mobile-sub-menu-width;
      background: #333132;
      transition: transform 0.5s ease;
      overflow-y: auto;
      overflow-x: hidden;

      &.menu-open {
        transform: translateX(-$mobile-menu-width);
      }

      .container {
        display: block;
        padding: 0;
      }
    }

    .menu-image {
      margin: 0 25px 0 0;
      width: 10px;
      // max-width: 325px;
      overflow: hidden;

      @media all and (max-width: $tablet-width) {
        display: none;
      }

      img {
        height: 100%;
        width: auto;
      }
    }

    .menu-sub {
      display: none;

      @media all and (max-width: $tablet-width) {
        display: block;
      }

      a {
        &:after {
          position: absolute;
          top: 17px;
          right: 10px;
          content: ">";
        }
      }
    }

    .menu-content {
      padding: 25px 40px 25px 0;
      transition: padding 0.3s ease;

      @media all and (max-width: $tablet-width) {
        padding: 0 !important;

        &.menu-content-sub {
          background: #333132;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          transition: transform 0.5s ease;
          transform: translateX($mobile-menu-width);

          &.menu-open {
            transform: translateX(0);
          }
        }
      }

      .menu-heading {
        padding: 0;

        @media all and (max-width: $tablet-width) {
          display: none;
        }
        h2 {
          font-size: 18px;
          font-weight: 500;
          color: white;
          line-height: 1em;
          padding: 0 0 0 10px;
        }
      }

      .menu-navigation {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        // max-height: 200px;

        @media all and (max-width: $tablet-width) {
          display: block;
          max-height: none;
        }

        a {
          &.back {
            display: none;

            @media all and (max-width: $tablet-width) {
              display: block;
              position: relative;
              padding-right: 30px !important;
              &:after {
                position: absolute;
                top: 17px;
                right: 10px;
                content: "<";
              }
            }
          }

          // min-width: $mobile-menu-width;
          max-width: $mobile-menu-width;
          position: relative;
          display: block;
          font-size: 1.3em;
          line-height: 1em;
          font-weight: normal;
          color: #fff;
          padding: 10px 10px 10px 11px;

          &:before {
            display: block;
            content: ">";
            position: absolute;
            left: 0;
            // color: white;
            opacity: 0;
            transition: opacity 0.3s ease;
          }

          &:hover,
          &:focus,
          &.active {
            color: #ffa018;

            &:before {
              opacity: 1;
            }
          }

          @media all and (max-width: $tablet-width) {
            width: $mobile-menu-width;
            font-size: 1.6em;
            padding: 17.8px 15px !important;
            border-bottom: 1px solid $main-color;
            color: #fff;

            &:before {
              display: none;
            }

            &:hover,
            &:focus,
            &.active,
            &.hover-active {
              color: #FFF;
              background: #ffa018;
            }
          }
        }
      }
    }

    &#menu-your-home-designs {
      .menu-content {
        padding: 0 0 25px 140px;
      }
    }

    .menu-content-flex-container {
      margin-left: auto;
      display: flex;
    }
  }
}

.search-field{
  padding-bottom: 30px;
}

#footer {
  position: relative;
  z-index: 2;
  border-top: 9px solid $main-color;
  background: $footer-bg-color;
  padding-top: 40px;

  h6 {
    font-size: 1.5em;
    font-weight: 500;
    color: #FFF;
    line-height: 1.2em;
    margin: 0 0 15px;
  }

  p,
  a {
    display: block;
    font-size: 1.2em;
    line-height: 1.2em;
    margin: 0 0 15px;
  }

  p {
    color: $footer-text-white-color;
  }

  a {
    color: $footer-text-color;
    &:hover,
    &:focus {
      color: white;
    }
  }

  #footer-navigations {
    clear: both;
    // display: flex;
    justify-content: space-between;
    padding: 55px 15px 20px;
    border-top: 1px solid #FFF;
    @media all and (max-width: $mobile-width) {
      flex-wrap: wrap;
    }

    .left {
      display: flex;

      @media all and (max-width: $mobile-width) {
        flex-wrap: wrap;
        width: 100%;
        padding: 0 0 40px 0;
        margin: 0 -10px;
      }

      @media all and (max-width: $small-mobile-width) {
      }

      .footer-link-container {
        padding: 0 50px 0 0;

        @media all and (max-width: $mobile-width) {
          padding: 10px 10px;
          width: 33.33333%;
        }

        @media all and (max-width: $small-mobile-width) {
          width: 50%;
        }

        @media all and (max-width: 374px) {
          width: 100%;
          padding-bottom: 20px;
        }

        .footer-main-links {
          padding: 30px 0 0 0;
          a {
            font-size: 1.4em;
            color: #FFF;
            font-weight: 300;

            &:hover,
            &:focus {
              color: white;
            }
          }
        }
      }
    }

    .right {
      @media all and (max-width: $mobile-width) {
        padding: 10px 10px;
        // padding: 5px 10px;
        // width: 100%;
      }
    }

    .footer-address-container {
      a {
        &:hover,
        &:focus {
          color: #FFF;
        }
      }
    }

    .footer-contact-container {
      margin: 40px 0 0 0;

      a {
        color: $footer-text-white-color;
        &:hover,
        &:focus {
          color: #FFF;
        }
        span {
          color: #FFF;
        }
      }
    }
  }

  #footer-logo-social {

    .container {
      display: flex;
      justify-content: space-between;
      padding: 20px 15px;

      @media all and (max-width: $mobile-width) {
        flex-wrap: wrap;
      }

      .left {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        @media all and (max-width: $mobile-width) {
          width: 100%;
          padding: 0 0 30px;
          flex-wrap: wrap;
          text-align: center;
          justify-content: center;
        }

        p,
        a {
          margin: 0;
          font-size: 1.2em;
          color: $footer-text-gray-color;
        }

        p {
          margin: 0 50px 0 0;
          // color: $main-color;
          // font-style: italic;
          font-size: 1em;

          @media all and (max-width: $mobile-width) {
            width: 100%;
            margin: 0 0 15px 0;
          }
        }

        a {
          margin: 0 35px 0 0;

          @media all and (max-width: $mobile-width) {
            margin: 10px;
          }

          &:hover,
          &:focus {
            color: white;
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        @media all and (max-width: $mobile-width) {
          width: 100%;
          justify-content: center;
        }

        p {
          font-size: 1.4em;
          font-weight: 300;
          color: $main-color;
          margin: 0 12px 0 0;
        }

        a {
          display: block;
          margin: 0 6px 0 0;

          &:last-child {
            margin: 0;
          }

          img {
            width: 36px;
          }

          &:hover,
          &:focus {
            opacity: 0.6;
          }
        }
      }

      .footer-logo-container {
        margin: 0 100px 0 0;

        @media all and (max-width: $mobile-width) {
          margin: 0 0 10px;
          width: 100%;
        }
        a {
          margin: 0;
          img {
            width: 112px;
          }
        }
      }
    }
  }
}

.hero {
  position: relative;
  height: 100vh;
  min-height: 400px;
  max-height: ($header-height + 576px);

  &.hero-inclusion {
    .hero-image {
      background-position: center right !important;
      @media all and (max-width: $mobile-width) {
        background-position: top right !important;
        background-repeat: no-repeat;
      }
    }
    h2 {
      font-size: 36pt;
      @media all and (max-width: $mobile-width) {
        font-size: 26pt;
      }
    }
  }

  &.hero-home {
    max-height: ($header-height + 720px);
  }

  @media all and (max-width: $tablet-width) {
    max-height: ($header-small-height + 576px);

    &.hero-home {
      max-height: ($header-small-height + 720px);
    }
  }

  @media all and (max-width: $mobile-width) {
    max-height: ($header-small-height + 350px);

    &.hero-home {
      min-height: 0;
      max-height: ($header-small-height + 720px);
    }

    &.hero-inclusion {
      max-height: ($header-small-height + 450px);
    }
  }

  @media all and (max-width: $small-mobile-width) {
    &.hero-home {
      min-height: 0;
      max-height: ($header-small-height + 380px);
    }

    &.hero-inclusion {
      margin-top: $header-small-height;
      max-height: none;
      height: auto;

      .hero-image {
        position: static;
        background-size: auto ($header-small-height + 100px) !important;
        background-position: top right !important;
        transform: translateY(0) !important;
      }

      .hero-content {
        position: static !important;
        padding-top: ($header-small-height + 100px + 20px);

        .hero-content-bottom {
          position: static !important;
        }
        h3 {
          font-size: 2.5em;
          letter-spacing: 0;
        }
      }
    }
  }

  .hero-image {
    position: absolute;
    top: $header-height;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center center !important;
    background-size: cover !important;

    @media all and (max-width: $tablet-width) {
      top: $header-small-height;
    }
  }

  .hero-slider {
    position: absolute;
    // top: $header-height;
    top: 100px;
    right: 0;
    bottom: 0;
    left: 0;

    @media all and (max-width: $tablet-width) {
      top: $header-small-height;
    }

    .swiper-slide {
      position: relative;
      background-position: center center !important;
      background-size: cover !important;

      .slider-content {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: auto;
        top: 30%;
        z-index: 3;
        transition: all 0.5s ease;
        transform: translateX(50px);
        opacity: 0;
        @media all and (max-width: $large-desktop-width) {
          top: 24%;
        }

        @media all and (max-width: $small-mobile-width) {
          bottom: auto;
          top: 15%;
        }

        h5 {
          position: relative;
          top: -20px;
          font-style: normal;
          font-weight: normal;
          font-size: 26px;
          line-height: 31px;
          color: #FFF;
          width: 55%;
          @media all and (max-width: $tablet-width) {
            font-size: 2.1em;
            width: 95%;
          }
          @media all and (max-width: $small-mobile-width) {
            font-size: 14px;
            line-height: normal;
          }
          @media all and (max-width: $super-small-mobile-width) {
            font-size: 14px;
            line-height: 18px;
          }
        }

        .btn-orange {
          padding: 13px 41px;
          font-family: $gotham;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          @media all and (max-width: $large-desktop-width) {
            padding: 10px 20px;
            font-size: 12px;
          }
        }

        h2 {
          margin: 0 0 30px;
          font-style: normal;
          font-weight: normal;
          font-size: 55px;
          line-height: 58px;
          letter-spacing: 0;
          color: #FFF;
          width: 50%;
          font-family: $itc-gothic;
          @media all and (max-width: $large-desktop-width) {
            font-size: 45px;
            line-height: 48px;
          }

          span {
            color: #ffa018;
          }

          @media all and (max-width: $tablet-width) {
            font-size: 4.5em;
            line-height: normal;
            width: 90%;
          }

          @media all and (max-width: $mobile-width) {
            font-size: 4em;
            line-height: normal;
            width: 95%;
          }

          @media all and (max-width: $small-mobile-width) {
            font-size: 2.2em;
            line-height: normal;
            font-weight: normal;
            width: 95%;
          }
        }
      }

      &:after {
        position: absolute;
        z-index: 1;
        content: "";
        display: block;
        top: 40%;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgb(0, 0, 0);
        background: linear-gradient( 0deg, rgba(0, 0, 0, 0.7) 0%,  rgba(255, 255, 255, 0) 100% );
      }

      &:before {
        position: absolute;
        z-index: 1;
        content: "";
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        // background: url("/wp-content/themes/aston/assets/img/bg-pattern.png") repeat;
        background: linear-gradient(30.3deg, #000000 0%, rgba(0, 0, 0, 0) 80%);
      }

      &.ready {
        .slider-content {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }

    .swiper-menu {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 2;
      padding: 0 0 30px;
      overflow-x: auto;

      @media all and (max-width: $tablet-width) {
        padding: 0 0 20px;
      }

      .container {
        display: flex;
      }

      a {
        display: block;
        width: 180px;
        margin: 0 20px;
        border-top: 2px solid $main-color;
        font-size: 1.3em;
        color: white;
        padding: 9px 2px;
        opacity: 0.3;
        min-width: 130px;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        &:hover,
        &.active {
          opacity: 1;
        }
      }
    }
  }

  .hero-content {
    position: relative;
    height: 100%;

    .hero-content-bottom {
      position: absolute;
      bottom: 10%;
    }

    h2 {
      margin: 0 0 10px;
      max-width: 650px;
      font-family: $itc-gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 59px;
      letter-spacing: 0;
      color: $heading-color;
    }
    h3 {
      margin: 0 0 15px;
      // letter-spacing: -3px;
      max-width: 650px;
      font-family: $itc-gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 36px;
      line-height: 39px;
      letter-spacing: 0;
      color: #ffa018;
    }
    p {
      font-size: 1.5em;
      line-height: 1.3em;
      color: $hero-text-color;
      max-width: 660px;
    }
  }
}

.page-content {
  background: white;
  position: relative;
  z-index: 2;
}

.page-container {
  background: white;
  position: relative;
  z-index: 2;
  margin-top: $header-height;

  @media all and (max-width: $tablet-width) {
    margin-top: $header-small-height;
  }
}

.promotion-container {
  padding: 70px 0 100px;
  text-align: center;

  .promotion-content {
    display: inline-block;
    text-align: left;

    img {
      display: inline-block;
      margin: 0 0 40px;
    }

    a {
      margin-left: 16px;
      border: 1px solid transparent;
      &:hover,
      &:focus {
        border: 1px solid #ffa018;
      }
    }
  }
}

.banner {
  h3 {
    line-height: 1em;
    font-size: 4.5em;
    font-weight: 300;
    color: white;
    letter-spacing: 0;
    margin: 0 0 20px 0;

    @media all and (max-width: $mobile-width) {
      font-size: 4em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 3.5em;
    }
  }

  p {
    line-height: 1.4em;
    font-size: 1.2em;
    color: white;
  }

  a.btn-orange {
    width: 147px;
    height: 45px;
    margin-top: 25px;
    // padding: 15px;
     background: $banner-btn-color;

    &:hover,
    &:focus {
      background: white;
    }
  }
}

#aftercare {
  padding: 35px 30px;
  display: flex;
  max-width: 1550px;
  margin: auto;
  background: white;

  @media all and (max-width: $tablet-width) {
    flex-wrap: wrap;
  }

  @media all and (max-width: $small-mobile-width) {
    padding: 35px 5px;
  }

  &.no-background {
    background: none;
  }

  .left {
    width: 50%;
    background-position: center center !important;
    background-size: cover !important;
    @media all and (max-width: $tablet-width) {
      width: 100%;
      height: 350px;
    }

    @media all and (max-width: $mobile-width) {
      height: 300px;
    }

    @media all and (max-width: $small-mobile-width) {
      height: 250px;
    }
  }

  .right {
    width: 50%;
    padding: 65px 100px;
    background: $banner-bg-color;

    @media all and (max-width: $tablet-width) {
      width: 100%;
      padding: (65px / 2) (100px / 2);
    }

    @media all and (max-width: $small-mobile-width) {
      padding: (65px / 3) (100px / 3);
    }

    p {
      max-width: 510px;
    }
  }
}

#choose-builder-guide {
  display: flex;
  background: $banner-bg-color;
  @media all and (max-width: $tablet-width) {
    flex-wrap: wrap;
    display: inline-block;
    width: 100%;;
  }

  .left {
    width: 50%;

    @media all and (max-width: $tablet-width) {
      width: 100%;
    }

    .banner-content {
      margin: 0 auto;
      max-width: 381px;
      padding: 200px 0 150px 0;

      @media all and (max-width: $tablet-width) {
        width: 100%;
        padding: 30px 35px 30px 20px;
        max-width: none;
      }

      @media all and (max-width: $small-mobile-width) {
        padding: (110px/3) (70px/3) (110px/3) (40px/3);
      }

      h3 {
        font-family: $itc-gothic;
        font-style: normal;
        font-weight: normal;
        font-size: 35px;
        line-height: 39px;
        text-align: right;
        letter-spacing: 0;
        color: #FFF;

        @media all and (max-width: $tablet-width) {
          text-align: center;
        }

        @media all and (max-width: $mobile-width) {

        }

        @media all and (max-height: $small-mobile-width) {

        }
      }

      h5 {
        font-family: $roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 25px;
        line-height: 30px;
        text-align: right;
        color: #FFF;
        margin-bottom: 33px;
      }

      p {
        font-family: $roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        text-align: right;
        color: #FFF;

        @media all and (max-width: $tablet-width) {
          font-size: 2em;
          text-align: center;
        }

        @media all and (max-width: $mobile-width) {
          font-size: 1.6em;
        }

        @media all and (max-width: $small-mobile-width) {
          font-size: 1.4em;
        }
      }

      a {
        font-family: $roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 26px;
        text-align: right;
        color: #FFF;
        display: block;
        @media all and (max-width: $tablet-width) {
          text-align: center;
        }
      }
    }
  }
  .right {
    width: 50%;
    background-position: center center !important;
    background-size: cover !important;

    @media all and (max-width: $tablet-width) {
      width: 100%;
      height: 350px;
      display: inline-block;
    }

    @media all and (max-width: $mobile-width) {
      height: 300px;
    }

    @media all and (max-width: $small-mobile-width) {
      height: 250px;
    }
  }
}

#newsletter {
  background: #ffa018;
  text-align: center;
  padding: 50px 0;
  @media all and (max-width: $tablet-width) {
    padding: 30px 0;
  }

  h3 {
    line-height: 1em;
    font-size: 3.5em;
    font-weight: 300;
    letter-spacing: 0;
    color: $main-color;
    margin: 0 0 8px;

    @media all and (max-width: $mobile-width) {
      font-size: 3em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 2.5em;
    }
  }

  p {
    font-size: 1.5em;
    font-weight: 300;
    color: #fff;
    width: 100%;

    @media all and (max-width: $mobile-width) {
      font-size: 1.3em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 1.2em;
    }
  }

  h2 {
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 39px;
    letter-spacing: 0;
    color: $heading-color;
    font-family: $itc-gothic;

    @media all and (max-width: $tablet-width) {
      font-size: 28px;
      line-height: 36px;
    }
  }
}

#house-and-land-packages {
  background: $gray-bg-color;
  padding: 110px 0;

  @media all and (max-width: $tablet-width) {
    padding: 30px 0 10px;
  }

  @media all and (max-width: $mobile-width) {
    display: inline-block;
    width: 100%;
  }

  @media all and (max-width: $small-mobile-width) {
    
  }

  .container {
    display: flex;

    @media all and (max-width: $tablet-width) {
      flex-wrap: wrap;
    }

    @media all and (max-width: $small-mobile-width) {
      display: block;
    }

    .house-and-land {
      padding: 0 15px 20px;

      @media all and (max-width: $tablet-width) {
        width: 33.33333%;
      }

      @media all and (max-width: $mobile-width) {
        width: 50%;
      }

      @media all and (max-width: $small-mobile-width) {
        width: 100%;
        max-width: 286px;
        margin-left: auto;
        margin-right: auto;
      }
    }

    .house-and-land-container {
      background: white;
      text-align: center;

      & > img {
        width: 100%;
      }

      .house-and-land-content {
        padding: 0 15px;
      }

      h4 {
        color: $main-color;
        line-height: 1em;
        font-weight: 300;
        font-size: 2.5em;
        margin: 20px 0;
        letter-spacing: 0;
      }

      .house-rooms {
        display: flex;
        justify-content: center;

        & > div {
          margin: 0 4px;
        }

        img {
          width: 21px;
          margin: 0 0 4px;
        }

        p {
          font-size: 1.3em;
          font-weight: 300;
          line-height: 1em;
          color: $main-color;
        }
      }

      .house-details {
        position: relative;
        margin-top: 8px;
        padding: 20px 0 15px;

        &:before {
          @include line();
        }

        &:after {
          content: "";
          display: block;
          width: 27px;
          height: 26px;
          background: white url("/wp-content/themes/aston/assets/img/icon-marker@2x.png") no-repeat;
          background-size: contain;
          margin-left: -13px;
          position: absolute;
          left: 50%;
          top: -13px;
        }

        h5 {
          color: $display-text-color;
          font-weight: normal;
          font-size: 1.3em;
          line-height: 1.4em;
          text-transform: uppercase;
          margin: 0 0 10px;
        }

        p {
          color: $display-text-color;
          font-size: 1.3em;
          line-height: 1.4em;
          margin: 0 0 2px;

          span {
            font-weight: bold;
            color: #1d1d1d;
          }
        }
      }

      .house-price {
        position: relative;
        padding: 18px 0 0;

        &:before {
          @include line();
        }

        h6 {
          font-weight: 300;
          font-size: 2em;
          line-height: 1em;
          margin: 0 0 20px;
          color: $main-color;
        }
      }

      .house-links {
        padding: 0 0 10px;
        a {
          width: 95px;
          margin: 0 3px 10px;
          padding: 8px 10px;

          &:hover {
            background: #ffa018;
            color: white;
          }
        }
      }
    }

    .house-and-land-slider {
      float: right;
      width: 63%;
      margin-left: 46px;
      @media all and (max-width: 1080px){
        float: right;
        width: 55%;
      }
      @media all and (max-width: 990px){
        width: 52%;
      }
      @media all and (max-width: 930px){
        width: 50%;
      }
     
      @media all and (max-width: 767px) {
        width: 100%;
        margin: 30px auto 0;
      }
     
      @media all and (max-width: $mobile-width) {
        
      }

     
      // @media all and (max-width: 885px){
      //   float: right;
      //   width: 61%;
      //   margin-left: 46px;
      // }
    }

    .house-and-land-details {
      max-width: 385px;
      margin: 0 auto;
      padding: 40px 53px;
      background-color: #FFF;
      height: 415px;
      overflow: auto;
      @media all and (max-width: 930px){
        width: 42%;
      }

      &.mobile {
        display: none;
      }

      
      @media all and (max-width: 767px) {
        width: 100%;
        max-width: none;
        text-align: center;
        margin: 0;
        padding: 30px 15px;
        display: none;
        display: block;
        height: auto;

        &.mobile {
          display: block;
        }
      }

      h3 {
        // @include heading-5em();
        margin: 0 0 20px;
        font-family: $itc-gothic;
        font-style: normal;
        font-weight: normal;
        font-size: 35px;
        line-height: 39px;
        letter-spacing: 0;
        color: $heading-color;
      }

      p {
        line-height: 1.25em;
        font-size: 1.5em;
        font-weight: 300;
        color: $text-color;
        max-width: 350px;
        font-family: $roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 26px;
        color: $heading-color;

        @media all and (max-width: $ipad-width) {
          font-size: 1.3em;
          max-width: 100%;
          margin: 0 0 0 0px;
        }
      }

      a {
        margin: 15px 0 0 0;
        background: #ffa018;
        border-radius: 10px;
        width: 173px;
        height: 45px;
        padding: 0;
        text-align: center;
        font-family: $gotham;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 45px;
        margin: 1px 0 0 0;
        
        &:focus,
        &:hover {
          color: #ffa018;
          background-color: #FFF;
          border: 1px solid #ffa018;
        }
        @media all and (max-width: $small-mobile-width) {
          margin: 20px 0 0 0;
        }
      }
    }
  }
}

.facades-content {
  background: $gray-bg-color;
  text-align: center;
  padding: 80px 0 0;

  @media all and (max-width: $tablet-width) {
    padding: 65px 0 0;
  }

  @media all and (max-width: $mobile-width) {
    padding: 50px 0 0;
  }

  @media all and (max-width: $small-mobile-width) {
    padding: 35px 0 0;
  }

  .container {
    padding: 0 25px;
  }

  h1 {
    font-family: $itc-gothic;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 59px;
    letter-spacing: 0.5px;
    color: $heading-color;
  }

  h2 {
    @include heading-5em;
    font-weight: 300;
    letter-spacing: 0;
    margin: 0 0 35px;
  }

  p {
    font-size: 1.5em;
    font-weight: 300;

    @media all and (max-width: $tablet-width) {
      font-size: 1.4em;
    }

    @media all and (max-width: $mobile-width) {
      font-size: 1.3em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 1.2em;
    }
  }

  .facades-container {
    margin: 0 -25px 0 -25px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 50px 0 40px 0;

    .facade {
      width: 33.33333%;
      padding: 0 25px 45px;

      @media all and (max-width: $mobile-width) {
        width: 50%;
      }

      @media all and (max-width: $small-mobile-width) {
        width: 100%;
      }

      & > img {
        width: 100%;
      }

      .details {
        background: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        p {
          font-weight: 400;
          font-size: 2em;
          color: $main-color;
          margin: 0;
          letter-spacing: 0;

          @media all and (max-width: $tablet-width) {
            font-size: 2em;
          }

          @media all and (max-width: $mobile-width) {
            font-size: 1.6em;
          }
        }

        a {
          font-size: 1.4em;
          padding: 6px 13px;
          background: #fdcb8b;

          @media all and (max-width: $tablet-width) {
            font-size: 1.3em;
          }

          @media all and (max-width: $tablet-width) {
            font-size: 1.2em;
          }

          img {
            width: 18px;
            opacity: 0.66;

            @media all and (max-width: $tablet-width) {
              width: 16px;
            }

            @media all and (max-width: $mobile-width) {
              width: 14px;
            }
          }

          &:hover,
          &:focus {
            background: $main-color;
            color: white;
          }
        }
      }
    }
  }

  &.essential {
    .facades-container {
      .facade {
        .details {
          p {
            color: $essential-color;
          }

          a {
            background: #87d49f;

            &:hover,
            &:focus {
              background: $essential-color;
            }
          }
        }
      }
    }
  }
}

#about {
  .about-content {
    padding: 170px 0 110px;

    @media all and (max-width: $tablet-width) {
      padding: 140px 0 90px;
    }

    @media all and (max-width: $mobile-width) {
      padding: 100px 0 60px;
    }

    @media all and (max-width: $small-mobile-width) {
      padding: 60px 0 30px;
    }

    .container {
      display: flex;
      justify-content: flex-start;
      margin: 0 auto 30px;

      &.content-left {
        text-align: right;
      }

      @media all and (max-width: $mobile-width) {
        flex-wrap: wrap;

        &.content-left {
          text-align: left;
          .right {
            text-align: center;
          }
        }

        &.content-right {
          .left {
            text-align: center;
          }
        }
      }
    }

    h3 {
      font-size: 3em;
      font-weight: 300;
      color: $main-color;
      letter-spacing: 0;
      margin: 0 0 10px;
    }

    h4 {
      font-weight: normal;
      font-size: 1.5em;
      color: $main-color;
      margin: 0 0 10px;
    }

    p {
      font-size: 1.3em;
      margin: 0 0 10px;
    }

    .left,
    .right {
      width: 50%;

      @media all and (max-width: $mobile-width) {
        width: 100%;
      }
    }

    .left {
      margin: 0 15px;

      @media all and (max-width: $mobile-width) {
        margin: 0 15px 30px;
      }
    }
    .right {
      margin: 0 0 0 30px;

      @media all and (max-width: $mobile-width) {
        margin: 0 15px 30px;
      }
    }
  }
}

#testimonial {
  .testimonial-container {
    .testimonial {
      padding: 55px 0;

      @media all and (max-width: $tablet-width) {
        padding: 20px 0;
      }

      h3 {
        margin: 0 0 20px;
      }
      h4 {
        font-weight: normal;
        font-size: 1.5em;
        color: $main-color;
        margin: 0 0 10px;
      }
      p {
        font-size: 1.5em;
        font-weight: 300;
        line-height: 1.4em;
        color: $basic-text-color;
        margin: 0 0 10px;
      }
      .testimonial-image {
        @media all and (max-width: $tablet-width) {
          text-align: center;
        }
      }
      .testimonial-video {
        display: block;
        position: relative;

        @media all and (max-width: $tablet-width) {
          text-align: center;
        }

        &::before {
          transition: all 0.3s ease;
          position: absolute;
          left: 50%;
          top: 50%;
          margin-left: -70px;
          margin-top: -48px;
          display: block;
          content: "";
          width: 139px;
          height: 96px;
          background: url("/wp-content/themes/aston/assets/img/btn-play.png") center center no-repeat;
        }

        &:hover,
        &:focus {
          &:before {
            transform: scale(0.8);
          }
        }
      }

      &.testimonial-left,
      &.testimonial-right {
        display: flex;

        @media all and (max-width: $tablet-width) {
          flex-wrap: wrap;
        }

        .left,
        .right {
          width: 50%;

          @media all and (max-width: $tablet-width) {
            width: 100%;
          }
        }
      }

      &.testimonial-left {
        .left {
          text-align: right;
          padding: 0 30px;

          @media all and (max-width: $tablet-width) {
            padding: 0 0 30px;
            text-align: left;
          }
        }
      }

      &.testimonial-right {
        .right {
          padding: 0 30px;

          @media all and (max-width: $tablet-width) {
            padding: 0 0 30px;
          }
        }

        .left {
          @media all and (max-width: $tablet-width) {
            padding: 0 0 30px;
          }
        }
      }

      &.testimonial-quote {
        h3 {
          margin: 0;
          font-size: 2.5em;
          line-height: normal;
          em,
          span {
            font-style: italic;
            font-size: 0.5em;
            color: $basic-text-color;
            font-weight: 300;
          }
        }
      }
    }
  }
}

#blog {
  background: $gray-bg-color;
}

#partners {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 110px 0;

  .partner {
    border-radius: 50%;
    margin: 12px;
    overflow: hidden;
  }
}

.faqs-accordion {
  margin: 60px 0 160px;
  background: none;
  border-bottom: 3px solid $accordion-border-color;
  text-align: left;

  @media all and (max-width: $tablet-width) {
    margin: 50px 0 130px;
  }

  @media all and (max-width: $mobile-width) {
    margin: 40px 0 100px;
  }

  @media all and (max-width: $small-mobile-width) {
    margin: 30px 0 70px;
  }

  li {
    border-top: 3px solid $accordion-border-color;
    padding: 4px 0;
    a {
      border: none !important;
      color: $text-color;
      font-size: 1.5em;
      font-weight: 300;
      line-height: 1em;
      padding: 20px 120px 20px 35px;
      &:hover,
      &:focus {
        background: $accordion-bg-color;
      }

      @media all and (max-width: $tablet-width) {
        padding: 15px 50px 15px 30px;
      }

      &:before {
        transition: all 0.3s ease;
        content: "";
        display: block;
        right: 80px;
        margin-top: -12px;
        width: 22px;
        height: 25px;
        background: url("/wp-content/themes/aston/assets/img/icon-double-arrow-down@2x.png") center
          center no-repeat;
        background-size: contain !important;

        @media all and (max-width: $tablet-width) {
          right: 10px;
          width: 15px;
          height: 17px;
          margin-top: -9px;
        }
      }
    }

    .accordion-content {
      position: relative;
      z-index: 2;
      margin: -5px 0 0 0;
      padding: 0 120px 20px 35px;
      background: $accordion-bg-color;

      @media all and (max-width: $tablet-width) {
        padding: 0 50px 15px 30px;
      }

      p {
        font-size: 1.3em;
        line-height: 1em;
        font-weight: 300;
        color: $accordion-text-color;
        margin: 0 0 10px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &.is-active {
      a {
        background: $accordion-bg-color;

        &:before {
          content: "";
          transform: rotate(180deg);
        }
      }
    }
  }
}

.contact-accordion {
  margin: 75px 0 90px;
  background: none;
  border: none !important;

  @media all and (max-width: $tablet-width) {
    margin: 55px 0 70px;
  }

  @media all and (max-width: $mobile-width) {
    margin: 35px 0 50px;
  }

  @media all and (max-width: $small-mobile-width) {
    margin: 20px 0 30px;
  }

  li {
    transition: all 0.3s ease;
    background: $accordion-bg-color;
    margin: 0 0 22px;
    &:last-child {
      margin: 0;
    }
    &:hover {
      background: darken($accordion-bg-color, 5%);
    }

    &.is-active {
      background: $accordion-bg-color !important;
    }

    a.accordion-title {
      font-size: 3em;
      font-weight: 300;
      color: $main-color;
      line-height: 1em;
      letter-spacing: 0;
      background: none !important;
      border: none !important;
      padding: 25px 100px;

      @media all and (max-width: $tablet-width) {
        font-size: 2.5em;
        padding: 20px 60px;
      }

      @media all and (max-width: $mobile-width) {
        font-size: 2em;
        padding: 20px 40px;
      }

      @media all and (max-width: $small-mobile-width) {
        padding: 15px 35px;
      }

      &:after {
        display: block;
        content: "";
        background: $main-color;
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 122px;
        height: 100%;
        transition: all 0.3s ease;

        @media all and (max-width: $tablet-width) {
          width: 35px;
        }
      }

      &:before {
        z-index: 2;
        transition: all 0.3s ease;
        content: "";
        display: block;
        right: 50px;
        margin-top: -12px;
        width: 22px;
        height: 25px;
        background: url("/wp-content/themes/aston/assets/img/icon-double-arrow-down-white@2x.png")
          center center no-repeat;
        background-size: contain !important;

        @media all and (max-width: $tablet-width) {
          right: 10px;
          width: 15px;
          height: 17px;
          margin-top: -9px;
        }
      }
    }

    &.is-active {
      a.accordion-title {
        &:before {
          content: "";
          transform: rotate(180deg);
        }
      }
    }

    .accordion-content {
      background: none !important;
      border: none !important;
      padding: 10px 100px 40px;

      @media all and (max-width: $tablet-width) {
        padding: 10px 70px 40px;
      }

      @media all and (max-width: $mobile-width) {
        padding: 10px 40px 30px;
      }

      @media all and (max-width: $small-mobile-width) {
        padding: 10px 20px 20px;
      }

      p {
        font-size: 1.5em;
        font-weight: 300;
        color: $basic-text-color;
        line-height: 1.2em;

        @media all and (max-width: $mobile-width) {
          font-size: 1.3em;
        }

        @media all and (max-width: $small-mobile-width) {
          font-size: 1.2em;
        }
      }

      form {
        @include contactForm();
      }
    }
  }
}

#contact-address {
  padding: 85px 0 50px;
  text-align: center;

  @media all and (max-width: $tablet-width) {
    padding: 65px 0 40px;
  }

  @media all and (max-width: $mobile-width) {
    padding: 45px 0 30px;
  }

  @media all and (max-width: $small-mobile-width) {
    padding: 25px 0 20px;
  }

  h2 {
    @include heading-5em;
    color: $main-color;
    margin: 0 0 45px;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 59px;
    letter-spacing: 0;
    // font-family: $itc-gothic;

    @media all and (max-width: $tablet-width) {
      margin: 0 0 40px;
    }

    @media all and (max-width: $mobile-width) {
      margin: 0 0 35px;
    }

    @media all and (max-width: $small-mobile-width) {
      margin: 0 0 35px;
    }
  }

  h3 {
    font-size: 1.5em;
    font-weight: 300;
    color: $contact-heading-color;
    line-height: 1.4em;
    margin: 0;
    letter-spacing: 0;

    @media all and (max-width: $mobile-width) {
      font-size: 1.3em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 1.2em;
    }
  }

  p {
    font-size: 1.5em;
    font-weight: 300;
    line-height: 1.4em;
    margin: 0 0 20px;

    @media all and (max-width: $mobile-width) {
      font-size: 1.3em;
    }

    @media all and (max-width: $small-mobile-width) {
      font-size: 1.2em;
    }
  }

  a {
    font-weight: 300;
    color: $text-color;

    &:hover,
    &:focus {
      color: $main-color;
    }
  }

  .two-column {
    @media all and (max-width: $mobile-width) {
      flex-wrap: wrap;
    }
    .left,
    .right {
      margin: 0 35px;

      @media all and (max-width: $mobile-width) {
        margin: 0 15px 15px;
      }
    }
    .left {
      text-align: right;

      @media all and (max-width: $mobile-width) {
        text-align: center;
        width: 100%;
      }
    }

    .right {
      text-align: left;

      @media all and (max-width: $mobile-width) {
        text-align: center;
        width: 100%;
      }
    }
  }
}

#contact-map {
  margin: 0;
  max-height: 620px;
  min-height: 400px;
  height: 39%;
}

#why-build-with-aston-homes {
  .basic-banner {
    p {
      max-width: 985px;
    }
  }
}

.reason-container {
  padding: 60px 0 0;

  .container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 0;
    // margin: 0 -15px;

    .reason {
      width: 205px;
      margin: 0 15px 25px;

      @media all and (max-width: $small-mobile-width) {
        width: calc(50% - 30px);
      }
      // &:first-child {
      //   margin-left: 0;
      // }
      // &:last-child {
      //   margin-right: 0;
      // }
      .reason-image {
        border-radius: 50%;
        overflow: hidden;
        margin: 0 0 10px;
      }

      h3 {
        font-size: 2em;
        font-weight: 300;
        line-height: 1.2em;
        margin: 0 auto;
        text-align: center;
        max-width: 120px;

        @media all and (max-width: $small-mobile-width) {
          font-size: 1.8em;
        }
      }
    }
  }
}

#our-story {
  padding: 20px 0 70px;
  text-align: center;

  h2 {
    @include heading-5em();
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 59px;
    letter-spacing: 0;
    color: $heading-color;
    font-family: $itc-gothic;
    @media all and (max-width: $tablet-width) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  p {
    max-width: 800px;
    margin: auto;
  }
}

#aston-homes-story-banner {
  background-size: cover !important;
  padding: 90px 50px;

  @media all and (max-width: $tablet-width) {
    padding: 70px 40px;
  }

  @media all and (max-width: $mobile-width) {
    padding: 50px 30px;
  }

  @media all and (max-width: $small-mobile-width) {
    padding: 30px 15px;
  }

  .aston-homes-story-banner-container {
    display: flex;
    background: rgba(247, 172, 77, 0.83);
    max-width: 1028px;
    margin: auto;

    @media all and (max-width: $tablet-width) {
      flex-wrap: wrap;
    }

    .left,
    .right {
      width: 50%;
      margin: 65px;

      @media all and (max-width: $tablet-width) {
        width: 100%;
        margin: 40px;
      }

      @media all and (max-width: $mobile-width) {
        margin: 30px;
      }

      @media all and (max-width: $small-mobile-width) {
        margin: 20px;
      }
    }

    h3 {
      @include heading-3em();
      color: white;
      line-height: 1em;
      margin: 0 0 15px;
      text-align: center;
    }

    p {
      font-size: 2em;
      font-weight: 300;
      line-height: 1.2em;
      text-align: center;
      color: white;

      @media all and (max-width: $tablet-width) {
        font-size: 1.6em;
      }
      @media all and (max-width: $mobile-width) {
        font-size: 1.4em;
      }
    }
  }
}

#our-range-of-services {
  padding: 60px 0 0;
  background: $gray-bg-color;

  h2 {
    @include heading-5em;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    line-height: 59px;
    letter-spacing: 0;
    color: $heading-color;
    font-family: $itc-gothic;
    @media all and (max-width: $tablet-width) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  h3 {
    text-align: center;
  }

  .container {
    padding: 0 30px;
  }

  .service-container {
    padding: 40px 0 20px 0;
    margin: 0 -30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .service {
      margin: 0 38px 40px;
      background: white;
    }

    img,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      max-width: 315px;
    }

    h4 {
      background: #f5e6d1;
      text-align: center;
      font-size: 3em;
      font-weight: normal;
      color: $main-color;
      letter-spacing: 0;
      padding: 15px 10px;
      line-height: 1em;
      margin: 0 0 20px;
    }

    p {
      font-size: 1.5em;
      font-weight: 300;
      color: $text-color;
      margin: 0;
      padding: 0 20px 20px;
      line-height: 1.35em;
    }
  }
}

.which-one-are-you {
  padding: 130px 0 170px;

  @media all and (max-width: $tablet-width) {
    padding: 100px 0 130px;
  }

  @media all and (max-width: $mobile-width) {
    padding: 70px 0 90px;
  }

  @media all and (max-width: $small-mobile-width) {
    padding: 40px 0 50px;
  }

  h3 {
    @include heading-5em();
    text-align: center;
    color: $heading-color;
    margin: 0 0 40px;
  }
}

.mouse-container {
  padding: 20px 0;
  display: block;
  position: relative;
  width: 35px;
  margin: auto;
  @media all and (max-width: $small-mobile-width) {
    transform: scale(0.8);
  }

  .mouse-wheel {
    // box-shadow: inset 0px 0px 5px white;
    position: absolute;
    width: 11px;
    height: 20px;
    top: 35px;
    left: 50%;
    margin-left: -5px;
    background: url(/wp-content/themes/aston/assets/img/icon-mouse-wheel.png);
    animation: mouseWheel 2s ease infinite;

    @media all and (max-width: $small-mobile-width) {
      transform: scale(0.8);
    }
  }
}

.customers {
  display: flex;
  margin: 0 -13px;
  margin: 40px 0 0 0;
  justify-content: center;
  flex-wrap: wrap;

  .customer {
    position: relative;
    background: #f8f6f4;
    border: 1px solid #eeecea;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    margin: 0 13px 40px;
    text-align: center;
    padding: 32px 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    min-height: 154px;
    min-width: 195px;

    @media all and (max-width: $mobile-width) {
      padding: 15px 10px;
      min-height: 134px;
      min-width: 175px;
    }

    &:hover,
    &:focus,
    &.active {
      background: $main-color;
      p,
      span {
        opacity: 0;
      }
      &:after {
        opacity: 1;
      }
    }

    &:before {
      position: absolute;
      display: block;
      content: "";
      bottom: -13px;
      left: 0;
      width: 100%;
      height: 2px;
      background: $main-color;
    }

    &:after {
      transition: all 0.3s ease;
      opacity: 0;
      position: absolute;
      content: "Yes,\AThat's me!";
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      color: white;
      white-space: pre;
      font-size: 2em;
      line-height: 1.2em;
      margin: 0;
      @include font-main();
    }

    p {
      font-size: 2em;
      line-height: 1.2em;
      margin: 0;

      @media all and (max-width: $mobile-width) {
        font-size: 1.6em;
      }
    }

    span {
      display: block;
      font-size: 1.3em;
      font-weight: 300;
      color: #aeaeae;
      line-height: 1.2em;
      letter-spacing: 0;
      margin: 10px 0 0;

      @media all and (max-width: $mobile-width) {
        font-size: 1.1em;
      }
    }
  }
}

#find-floor-plans {
  // background: $gray-bg-color;
  padding: 30px 0 10px;

  @media all and (max-width: $tablet-width) {
    padding: 30px 0;
  }

  @media all and (max-width: $mobile-width) {
    // padding: 0 0 70px;
  }

  @media all and (max-width: $small-mobile-width) {
    // padding: 30px 0 10px;
  }

  h3 {
    // @include heading-5em();
    color: $heading-color;
    margin: 0;
    font-style: normal;
    font-size: 35px;
    line-height: 59px;
    text-align: center;
    letter-spacing: 0;
    font-weight: bold;
    font-family: $itc-gothic;
    @media all and (max-width: $tablet-width) {
      line-height: normal;
    }
  }

  h4 {
    text-align: center;
    font-size: 2em;
    font-weight: 300;
    color: $text-color;
  }
}

#form-search-floor-plans,
#form-floor-plans {
  padding: 40px 33px 0 33px;
  @media all and (max-width: $tablet-width) {
    padding: 30px 15px 0 15px;
  }
  @media all and (max-width: $mobile-width) {
    padding: 20px 15px 0 15px;
  }

  .input-group-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -33px;

    @media all and (max-width: $mobile-width) {
      margin: 0 -15px 15px;
    }

    .input-group {
      // display: block;
      width: auto;
      margin: 0 33px 40px;
      // width: 250px;
      display: block;

      @media all and (max-width: $mobile-width) {
        margin: 0 15px 20px;
      }

      & > label {
        text-align: center;
        font-size: 1.6em;
        line-height: 1em;
        color: $text-color;
        display: block;
        margin: 20px 0;
        font-weight: 700;
        font-family: $roboto;
        min-height: 40px;

        img {
          height: auto;
          width: 40px;
          margin-right: 10px;
        }

        @media all and (max-width: $mobile-width) {
          font-size: 1.3em;
        }
      }

      .input-container {
        display: flex;
        justify-content: center;
        margin: 0 -5px;
        background: #F0EEEB;
        border-radius: 65px;
        .radio-text-container {
          margin: 0;
          width: auto;
          height: auto;
          border: none;
          padding: 6px 10px;
          border-radius: 65px;
          background: transparent;
          label:hover,
          input[type="radio"]:checked + label {
            background: $heading-color;
            color: #FFF;
            font-weight: 700;
          }
          label {
            background: transparent;
            border-radius: 65px;
            width: 87px;
            height: 46px;
            font-family: $roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            line-height: 42px;
            color: $heading-color;
            @media all and (max-width: $tablet-width) {
              width: 47px;
            }
          }
        }
      }
    }
  }

  .submit-container {
    text-align: center;

    .input-group {
      width: 27.33%;
      @media all and (max-width: $tablet-width) {
        margin-bottom: 0;
        width: 100%;
      }
    }

    button {
      width: 173px;
      height: 45px;
      background: #ffa018;
      border-radius: 10px;
      font-family: $gotham;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #FFF;
      padding: 0;

      &:hover,
      &:focus {
        color: #ffa018;
        background-color: #FFF;
        border: 1px solid #ffa018;
      }
    }
  }
}

#investor {
  .basic-banner {
    .customers {
      margin: 60px -13px 65px;
    }
  }

  .investor-content {
    padding: 85px 0 75px;

    h2 {
      @include heading-3em();
      text-align: center;
    }

    img {
      text-align: center;
    }

    p {
      font-size: 1.5em;
      line-height: 1.3em;
    }
  }
}

.background-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  background: black;
  opacity: 0.74;
}

.btn-close {
  display: block;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: $main-color;
  color: white;
  text-align: center;
  cursor: pointer;
  font-size: 3em;
  line-height: 1.4em;
  transform: rotate(45deg);

  &:hover,
  &:focus {
    color: white;
  }
}

#enquiry,
#enquiry-floorplan,
#investor-form {
  display: none;
}
.enquiry-form-container,
.investor-form-container {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
  }

  .enquiry-form,
  .investor-form {
    z-index: 201;
    position: relative;
    max-width: 1165px;

    .enquiry-content,
    .investor-content {
      max-height: 80vh;
      padding: 100px 40px 70px;
      overflow: auto;
      background: $enquiry-bg-color;

      @media all and (max-width: $tablet-width) {
        padding: 80px 35px 50px;
      }

      @media all and (max-width: $mobile-width) {
        padding: 60px 30px 30px;
      }

      @media all and (max-width: $small-mobile-width) {
        padding: 40px 20px 25px;
      }
    }

    h2 {
      @include heading-3em();
      line-height: 1.1em;
      text-align: center;
      margin: 0 0 30px;
      color: $text-color;
    }

    .house-name {
      color: #231f20;
    }

    p {
      font-size: 1.5em;
      font-weight: 300;
      text-align: center;
      margin: 0;

      &.form-terms {
        font-size: 1.2em;
        line-height: 1.2em;
        margin: 12% 0 0 0;
      }
    }

    .btn-close {
      position: absolute;
      top: -21px;
      right: 30px;
    }

    form {
      @include contactForm();
    }
  }
}

// #display-home,
#house-and-land {
  .basic-banner {
    padding-bottom: 30px;
    // box-shadow: 0 0 80px #989898;
    position: relative;
    z-index: 2;

    h5 {
      margin: 30px 0 0 0;
    }
  }
}

#house-and-land {
  .basic-banner.hero-banner h1 {
    // font-family: $itc-gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 59px;
    letter-spacing: 0;
  }
}

#display-home-map,
#house-land-map {
  height: 620px;
  max-height: 80vh;
  min-height: 400px;
}

.display-home-locations {
  padding: 40px 0;
  background-color: #f0eeec;

  @media all and (max-width: $tablet-width) {
    padding: 55px 0;
  }

  @media all and (max-width: $mobile-width) {
    padding: 40px 0;
  }

  @media all and (max-width: $small-mobile-width) {
    padding: 25px 0;
  }

  .display-home-locations-container {
    display: flex;
    margin: 0 -14px;
    flex-wrap: wrap;

    @media all and (max-width: $mobile-width) {
      justify-content: center;
    }
  }

  .display-home-location-container {
    width: 25%;

    @media all and (max-width: $tablet-width) {
      width: 33.33333%;
    }
    @media all and (max-width: $mobile-width) {
      width: 100%;
      max-width: 260px;
    }
  }

  .display-home-location {
    background: #FFF;
    margin: 0 14px 28px;
    padding: 28px;
  }

  h2 {
    // color: $display-text-color;
    // font-size: 4em;
    margin: 0 0 15px;
    // font-family: $itc-gothic;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: normal;
    letter-spacing: 0;
    color: $heading-color;

    @media all and (max-width: $desktop-width) {
      font-size: 3.5em;
    }

    @media all and (max-width: $tablet-width) {
      font-size: 3em;
    }

    @media all and (max-width: $mobile-width) {
      font-size: 2.5em;
    }
  }

  p,
  a {
    color: $display-text-color;
  }

  p {
    font-size: 1.3em;

    @media all and (max-width: $mobile-width) {
      font-size: 1.2em;
    }
  }

  a {
    &:hover,
    &:focus {
      color: $main-color;
    }
  }

  a.display-home-location-map {
    color: $main-color;
  }
}

#house-and-land-packages-horizontal {
  padding: 50px 0;
  background-color: #f0eeec;

  @media all and (max-width: $tablet-width) {
    padding: 60px 0;
  }

  @media all and (max-width: $mobile-width) {
    padding: 30px 0 7px;
  }

  .house-and-land-container {
    display: flex;
    background: #FFF;
    border: 1px solid $house-land-border;
    margin: 0 0 40px;

    @media all and (max-width: 1080px) {
      background: none;
      border: none;
      flex-wrap: wrap;
    }

    @media all and (max-width: $mobile-width) {
      margin: 0 0 40px;
    }

    @media all and (max-width: $small-mobile-width) {
      margin: 0 0 30px;
    }

    .house-image {
      img {
        height: auto;
        width: 400px;
      }

      @media all and (max-width: 1080px) {
        img {
          height: auto;
        }
      }

      @media all and (max-width: $tablet-width) {
        width: 100%;
        text-align: center;
      }
    }

    .house-and-land-content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      @media all and (max-width: $mobile-width) {
        flex-wrap: wrap;
      }

      @media all and (max-width: 1080px) {
        background: $house-land-bg;
        border: 1px solid $house-land-border;
      }

      .left,
      .right {
        padding: 35px;

        @media all and (max-width: $mobile-width) {
          width: 100%;
          padding: 20px;
          text-align: center;
        }
      }

      .left {
        a {
          font-size: 1.3em;
          font-weight: normal;
        }
      }

      .right {
        text-align: center;

        p {
          font-size: 1.3em;
          margin: 10px 0 20px;
        }

        a.btn-orange {
          min-width: 105px;
          margin: 0 6px 6px;
          color: white;

          &:hover,
          &:focus {
            color: $main-color;
          }
        }
      }
    }

    .house-rooms {
      display: flex;
      margin: 25px -12px 0;
      flex-wrap: wrap;

      @media all and (max-width: $mobile-width) {
        justify-content: center;
      }

      div {
        margin: 0 12px;

        img {
          width: 35px;
        }

        p {
          text-align: center;
          font-size: 1.5em;
          color: $main-color;
          margin: 8px 0 0;
        }
      }
    }

    .house-links {
      margin: 0 0 20px;
    }

    h2 {
      // @include heading-3em;
      // color: $main-color;
      font-size: 3em !important;
      font-weight: 600 !important;
      color: $heading-color;
      margin: 0 0 15px;
      line-height: normal;
    }

    h3 {
      @include heading-3em;
      color: $main-color;
      margin: 0 0 25px;
    }

    p,
    a {
      color: $display-text-color;

      strong {
        color: $display-text-bold-color;
      }
    }

    a.share {
      color: #bdbdbd;
      font-size: 1.1em;

      img {
        margin: -2px 2px 0;
      }

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }
}

.dev-house-and-land-packages-horizontal h2 {
  // font-family: $itc-gothic;
  font-style: normal;
  font-weight: bold !important;
  font-size: 30px !important;
  line-height: 59px;
  letter-spacing: 0;
}

.privacy-content {
  position: relative;
  z-index: 1046;
  max-width: 960px;
  padding: 30px;
  background: #fff;
  @include font-main();
  margin: 20px auto 0;

  h1 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: 700;
    font-size: 25px;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 12px;
  }

  li,
  p {
    margin: 0 0 10px;
    font-size: 12px;
  }

  a {
    color: $main-color;

    &:hover,
    &:focus {
      color: black;
    }
  }

  li {
    margin-bottom: 5px;
  }

  ol,
  ul {
    margin-bottom: 8px;
  }
}

#inclusions {
  .download-inclusion {
    text-align: center;
    padding: 45px 0;

    h1 {
      font-family: $itc-gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 35px;
      line-height: 59px;
      letter-spacing: 0;
      color: $heading-color;
      @media all and (max-width: $tablet-width) {
        font-size: 28px;
        line-height: 36px;
      }
    }

    h2 {
      @include heading-3em();
      font-size: 2.2em;
      margin: 0 0 18px;
    }
  }

  .inclusions-container {
    position: relative;
    margin: 0 0 150px;

    @media all and (max-width: $tablet-width) {
      margin: 0 0 120px;
    }

    @media all and (max-width: $mobile-width) {
      margin: 0 0 90px;
    }

    @media all and (max-width: $small-mobile-width) {
      margin: 0 0 70px;
    }

    .slogan {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      width: 230px;
      height: 230px;
      margin: -115px 0 0 -115px;
      border-radius: 50%;
      font-size: 2.1em;
      @include font-kremlin;
      color: white;
      text-transform: uppercase;
      text-align: center;
      font-style: italic;
      line-height: 1.4em;
      letter-spacing: 0;

      img {
        height: 230px;
      }

      @media all and (max-width: $mobile-width) {
        position: relative;
        margin: 0 0 15px -115px;
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;

      .inclusion {
        position: relative;
        width: 50%;
        padding: 9px;

        @media all and (max-width: $mobile-width) {
          width: 100%;
          text-align: center;
        }

        @media all and (max-width: $small-mobile-width) {
          padding: 0;
          margin: 0 0 15px;
        }

        .inclusion-imgage {
          position: relative;
        }

        .hotspot {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 14px;
          height: 14px;

          .dot {
            border-radius: 50%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 14px;
            height: 14px;
            background: $main-color;
            cursor: pointer;
            animation: pulse 2s infinite;
          }

          &:hover {
            z-index: 10;
            .content {
              transform: scale(1);
            }
          }

          .content {
            transform: scale(0);
            transform-origin: 50% 100%;
            display: block;
            position: absolute;
            bottom: 25px;
            background: rgba(247, 172, 77, 0.75);
            padding: 10px 12px;
            width: 170px;
            margin-left: -78px;
            transition: transform 0.3s ease;
            text-align: center;

            @media all and (max-width: $small-mobile-width) {
              padding: 6px 8px;
            }

            &.bottom-left {
              margin-left: 0;
              transform-origin: 0 100%;
            }

            &.bottom-right {
              margin-left: -156px;
              transform-origin: 100% 100%;
            }

            &.top-left {
              bottom: auto;
              top: 25px;
              margin-left: 0;
              transform-origin: 0 0;
            }

            &.top-right {
              bottom: auto;
              top: 25px;
              margin-left: -156px;
              transform-origin: 100% 0;
            }

            &.top {
              bottom: auto;
              top: 25px;
              transform-origin: 50% 0;
            }

            h3 {
              font-size: 1.6em;
              color: white;
              margin: 0 0 5px;
              letter-spacing: 0;
              font-weight: normal;

              @media all and (max-width: $small-mobile-width) {
                font-size: 1.4em;
              }
            }

            p {
              font-size: 1.3em;
              color: white;
              margin: 0 0 3px;
              line-height: 1.2em;

              @media all and (max-width: $small-mobile-width) {
                font-size: 1.2em;
              }

              &:last-child {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

#floor-plan {
  .basic-banner {
    background: #fff;
    p {
      max-width: 1120px;
    }

    h1 {
      font-family: $itc-gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 35px;
      line-height: 59px;
      letter-spacing: 0;
      color: $heading-color;
    }

    form#form-floor-plans {
      padding: 0;
      margin: 30px 0 0;

      .land-size-container {
        padding: 40px 0 20px 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .land-width-container {
          margin-right: 35px;
        }

        .land-depth-container {
          margin-left: 35px;
        }

        @media all and (max-width: 850px) {
          display: block;
          .land-width-container,
          .land-depth-container {
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      .land-width-container,
      .land-depth-container {
        width: 325px;
        max-width: 100%;
        margin: -25px auto 30px;

        .irs-min,
        .irs-max,
        .irs-single {
          display: none;
        }

        .irs-line {
          background: #f8f6f4;
          border: 1px solid #e6e4e2;
        }

        .irs-bar {
          display: none;
        }

        .irs-grid {
          .irs-grid-pol {
            display: none;
          }
          .irs-grid-text {
            @include font-main();
            font-size: 12px;
            color: $text-color;
          }
        }

        .irs-handle {
          top: 21px;
          border-radius: 3px;
          width: 21px;
          height: 19px;
          background: $main-color;
          cursor: pointer;

          i {
            display: none;
          }
        }
      }

      .land-depth-container {
        width: 415px;
      }

      .input-group-container {
        margin: 0 -15px 15px;
        .input-group {
          margin: 0 15px 20px;
        }
      }

      .submit-container {
        a,
        button {
          margin: 0 6px 14px;
          position: relative;
          // &:before {
          //   content: "";
          //   display: block;
          //   width: 100%;
          //   height: 1px;
          //   background: $main-color;
          //   position: absolute;
          //   top: -10px;
          //   left: 0;
          // }
        }

        button {
          min-width: 170px;
          width: auto;
          padding: 5px 20px;
          border: 1px solid transparent;
          &:hover,
          &:focus {
            border: 1px solid #ffa018;
          }
        }
        a.dev-btn-gray,
        button.dev-btn-gray {
          min-width: 215px;
          background-color: $heading-color;
          border: 1px solid transparent;
          padding: 0;
          border-radius: 10px;
          font-family: $gotham;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          color: #FFF;
          height: 45px;
          line-height: 45px;
          &:hover,
          &:focus {
            background-color: #FFF;
            color: $heading-color;
            border: 1px solid $heading-color;
          }
        }

        button.btn-green {
          border: 1px solid transparent;
          background-color: #52b113;
          margin: 0 26px 14px;
          &:hover,
          &:focus {
            background-color: #FFF;
            color: #52b113;
            border: 1px solid #52b113;
          }
        }
      }

      h3 {
        font-size: 2.2em;
        margin: 0 auto 10px;
      }

      h4 {
        font-weight: normal;
        font-size: 1.6em;
        color: $text-color;
        margin: 0 auto 10px;

        sub {
          bottom: 0;
          font-size: 0.75em;
        }

        img {
          margin: 0 0 0 5px;
          height: 16px;

          &.land-depth-icon {
            height: 13px;
          }
        }
      }

      .bar {
        margin: 0 0 40px;
      }
    }
  }
}

.filtered-posts{
  // padding-bottom: 80px;
  padding-bottom: 1px !important;
  @media all and(max-width:768px) {
    padding-bottom: 40px;
  }
  
}
.search-filter{

  // margin-bottom: 80px;
  color: #494948;
  font-size: 18px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  color: #000;

  .no-search-filter{
    margin-bottom: 300px;
    @media all and (max-width:768px) {
      margin-bottom: 200px;
    }
  }

 
  @media all and (max-width: $tablet-width) {
    font-size: 20px;
  }
}

#floor-plan-result.after-floor-plan-result{
 padding-bottom: 40px;
 padding-top: 0;
} 

#floor-plan-result {
  padding: 40px 0 40px;
  // padding-bottom: 40px;
  background-color: #f0eeec; 
  @media all and (max-width: $tablet-width) {
    padding: 40px 0;
    padding-bottom: 1px;
  }

  @media all and (max-width: $mobile-width) {
    padding: 40px 0 0;
  }

  @media all and (max-width: $small-mobile-width) {
    padding: 20px 0;
  }

  h3.no-result {
    display: none;
  }

  .floor-plan {
    padding: 30px;
    margin-bottom: 40px;
    background-color: #FFF;

    @media all and (max-width: $mobile-width) {
      padding: 0 0 40px;
    }


    @media all and (max-width: $small-mobile-width) {
      padding: 0 0 10px;
    }

    &:last-child {
      padding: 30px;
    }

    .floor-plan-header {
      border-bottom: 2px dotted #d4d4d4;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 10px 20px;

      @media all and (max-width: 600px) {
        display: block;
        padding: 20px 15px 10px;
      }

      .floor-plan-title {
        display: flex;

        @media all and (max-width: 600px) {
          display: block;
        }

        h3 {
          margin: 0 40px 0 0;
          color: $heading-color;
          font-weight: 600;

          @media all and (max-width: 600px) {
            margin: 0 0 10px;
          }
        }

        .house-rooms {
          display: flex;
          margin: 0;
          flex-wrap: wrap;

          @media all and (max-width: 600px) {
            margin: 0 -4px;
          }
          div {
            margin: 0 4px;

            img {
              width: 20px;
            }

            p {
              text-align: center;
              font-size: 1.3em;
              color: $main-color;
              margin: 2px 0 0;
            }
          }
        }

        .floor-plan-type {
          font-size: 2em;
          font-weight: 300;
          color: $main-color;
          letter-spacing: 0;
          margin: 3px 0 0 50px;

          @media all and (max-width: $tablet-width) {
            font-size: 1.8em;
            margin: 3px 0 0 30px;
          }

          @media all and (max-width: $mobile-width) {
            font-size: 1.6em;
            margin: 3px 0 0 20px;
          }

          @media all and (max-width: 600px) {
            margin: 3px 0 0 0;
          }

          &.essential {
            color: $essential-color;
          }
        }
      }

      .floor-plan-share {
        a {
          font-size: 1.1em;
          color: #bdbdbd; 
        
          img {
          
            margin: 0 0 6px 3px;
          }
        }
      }
    }

    .floor-plan-content {
      padding: 20px;
      display: flex;
      justify-content: flex-start;

      @media all and (max-width: $tablet-width) {
        flex-wrap: wrap;

        & > div {
          width: 50%;
          padding-bottom: 30px !important;
        }
      }

      @media all and (max-width: 600px) {
        justify-content: center;
        padding: 15px;
        & > div {
          width: 100%;
          padding: 0 0 30px !important;
        }
      }

      .floor-plan-details {
        @media all and (max-width: 375px) {
          
        }
        a.btn-enquire-floorplan {
          @media all and (max-width: 375px) {
            font-size: 12px;
            width: 130px;
            height: 36px;
            line-height: 36px;
            padding: 0;
            margin-top: 22px;
            text-align: center;
            margin: 22px 0 0 0;
          }
          @media all and (max-width:320px){
            font-size: 12px;
            width: 111px;
            height: 36px;
            line-height: 36px;
            padding: 0;
            margin-top: 40px;
            text-align: center;
            margin: 8px 0 0 0;
          }
        }
        /*a.btn-enquire-floorplan.btn-download-mobile {
          display: none;
        }
        @media all and (max-width: 375px) {
          a.btn-enquire-floorplan.btn-download-mobile {
            display: inline-block;
            color: #FFF;
            width: 100px;
            font-size: 20px;
            padding: 8px 10px;
            clear: both;
            &:focus,
            &:hover {
              color: $heading-color;
              background-color: $main-color;
            }
          }
        }*/
        h4 {
          font-size: 1.5em;
          margin: 15px 0 6px 0;
          text-align: left;
          color: $heading-color;
          font-weight: 600;

          &:first-child {
            margin: 0 0 6px;
          }
        }
        p {
          font-size: 1.3em;
          color: $text-color;
          margin: 0 0 6px;

          strong {
            color: #1d1d1d;
          }
        }

        table {
          width: auto;
          margin: 0;
          tbody {
            border: none;

            tr:nth-child(even) {
              background: none;
            }
          }
          tr {
            td {
              &:first-child {
                padding-right: 10px;
              }
              background: none;
              padding: 0 0 6px;
              @include font-main();
              font-weight: 400;
              font-size: 1.3em;
              color: $text-color;
              margin: 0 0 6px;
              text-align: left;
              padding-right: 10px;
              p {
                font-size: 1em;
              }

              strong {
                color: #1d1d1d;
              }
            }
          }
        }
      }

      .floor-plan-buttons {
        padding: 0 25px;
        @media all and (max-width: $small-mobile-width){
          padding: 0 1px;
          // padding: 0 5px;
        }
        .btn-gray {
          padding: 8px 10px;
          color: $form-input-text-color;
          border: 1px solid $house-land-border;
          display: block;
          margin: 0 0 7px;
          width: 205px;
          border-radius: 5px;
          font-size: 14px;
          text-align: center;
          @media all and (max-width: $small-mobile-width) {
            font-size: 12px;
            width: 155px;
          }
          @media all and (max-width: 375px) {
            margin: 0 auto 20px;
            display: none;
          }
          &:hover,
          &:focus {
            background: $main-color;
            border: 1px solid $main-color;
            color: white;
          }
        }
        .btn-download-mobile {
          display: none;
        }
        @media all and (max-width: 375px) {
          .btn-download-mobile {
            display: inline-block;
            width: 130px;
            clear: both;
            font-weight: 600;
            i {
              margin-right: 5px;
            }
            &:focus,
            &:hover {
              color: $heading-color;
            }
          }
        }

        .btn-orange {
          width: 205px;
          font-size: 1.3em;
          margin: 40px 0 0 0;
          text-align: center;
          padding: 12px;
          &:hover,
          &:focus {
            background: #ffa018;
            color: white;
          }
        }
      }

      .floor-plan-image {
        display: flex;
        align-items: flex-start;
        padding: 0 40px 0 0;

        a {
          display: block;
          min-width: 39px;
        }

        img {
          max-width: 223px;
          //max-height: 235px;
        }
      }

      .floor-plan-facades {
        margin-left: auto;
        .floor-plan-facades-container {
          width: 336px;
          max-width: 100%;
          .swiper-wrapper {
            &.essential {
              p {
                // color: $essential-color;
               margin-bottom: 10px;
                color: $heading-color;
              }
            }
          }
          @media all and (max-width: 767px){
            // .swiper-wrapper{

            // }
          }
          p {
            display: flex;
            justify-content: center;
            align-items: center;
            // color: $main-color;
            color: $heading-color;
            font-size: 1.5em;
            text-align: center;
            margin: 5px 0 0 0;

            .btn-prev,
            .btn-next {
              display: inline-block;

              i {
                // color: $main-color;
                color: $heading-color;
                font-size: 2.8em;
              }

              &:hover {
                opacity: 0.5;
              }
            }

            .btn-prev {
              margin: 0 20px 0 0;
            }

            .btn-next {
              margin: 0 0 0 20px;
            }
          }
        }
      }
    }
    
  
  
  }
}

.mfp-img-white {
  .mfp-figure:after {
    background: white;
  }
}

.mfp-facades {
  .mfp-counter {
    display: none;
  }

  img.mfp-img {
    padding: 70px 0 70px;
  }

  .mfp-figure:after {
    top: 70px;
    bottom: 70px;
  }

  .mfp-arrow-left {
    width: 96px;
    height: 34px;
    opacity: 1;
    margin: 0 0 0 -103px;
    top: auto;
    bottom: 20px;
    left: 50%;
    transform-origin: 0;

    @media all and (max-width: 900px) {
      margin: 0px 0 0 -93px;
    }

    &:before {
      content: "< Previous";
      display: block;
      border: none;
      width: auto;
      height: auto;
      margin: 0;
      font-size: 1.4em;
      color: white;
      background: $main-color;
      line-height: 1em;
      text-align: center;
      padding: 10px 0;
      width: 96px;
      opacity: 1;
      border-radius: 5px;
    }
    &:after {
      display: none;
    }
  }

  .mfp-arrow-right {
    width: 96px;
    height: 34px;
    opacity: 1;
    margin: 0 0 0 7px;
    top: auto;
    bottom: 20px;
    left: 50%;
    transform-origin: 0;

    @media all and (max-width: 900px) {
      margin: 0 0 0 -3px;
    }

    &:before {
      content: "Next >";
      display: block;
      border: none;
      width: auto;
      height: auto;
      margin: 0;
      font-size: 1.4em;
      color: white;
      background: $main-color;
      line-height: 1em;
      text-align: center;
      padding: 10px 0;
      width: 96px;
      opacity: 1;
      border-radius: 5px;
    }
    &:after {
      display: none;
    }
  }

  .mfp-bottom-bar {
    top: 20px;
    bottom: auto;
    margin-top: 0;
    background: none;

    @media all and (max-width: $tablet-width) {
      top: 23px;
    }

    @media all and (max-width: $mobile-width) {
      top: 27px;
    }

    @media all and (max-width: $small-mobile-width) {
      top: 37px;
    }

    .mfp-title {
      text-align: center;
      font-size: 4em;
      @include font-main;
      color: $main-color;
      line-height: 1em;
      font-weight: 300;
      letter-spacing: 0;
      padding: 0;

      @media all and (max-width: $tablet-width) {
        font-size: 3.5em;
      }

      @media all and (max-width: $mobile-width) {
        font-size: 3em;
      }

      @media all and (max-width: $small-mobile-width) {
        font-size: 2.5em;
      }
    }
  }
  .mfp-close {
    display: block;
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background: $main-color;
    color: white;
    text-align: center;
    cursor: pointer;
    font-size: 3em;
    line-height: 1.4em;
    opacity: 1;
    padding: 0;
    // right: 10px;
    top: 50px;

    &:hover,
    &:focus {
      color: white;
    }
  }
}

#blog-page {
  padding: 40px 0 100px;

  @media all and (max-width: $tablet-width) {
    padding: 30px 0 80px;
  }

  @media all and (max-width: $mobile-width) {
    padding: 30px 0 60px;
  }

  @media all and (max-width: $small-mobile-width) {
    padding: 30px 0 40px;
  }
}

#blog-page .container {
  max-width: 1248px;

  @media all and (max-width: 1248px) {
    max-width: 100%;
  }
}

#blog-page .container .blog-list {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

#blog-page .container .blog-list .blog {
  margin: 30px 15px 15px 15px;
  width: calc(33.333333% - 30px);
  position: relative;
  background: white;
  padding: 25px 25px 53px;
  border-bottom: none;
  -webkit-box-shadow: 0 4px 24px rgba(0, 0, 0, 0.025);
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.025);
}

@media all and (max-width: 900px) {
  #blog-page .container .blog-list .blog {
    width: calc(50% - 30px);
  }
}

@media all and (max-width: 600px) {
  #blog-page .container .blog-list .blog {
    width: calc(100% - 30px);
  }
}

#blog-page .container .blog-list .blog .blog-image a {
  display: block;
  margin: 0 0 15px;
}

#blog-page .container .blog-list .blog h2.title {
  font-size: 3.2em;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0;
  margin: 0 0 15px;
  a {
    font-family: $itc-gothic;
    font-style: normal;
    font-weight: normal !important;
    font-size: 20px;
    line-height: normal !important;
    letter-spacing: 0;
    color: $heading-color;
  }
}

@media all and (max-width: $small-mobile-width) {
  #blog-page .container .blog-list .blog h2.title {
    font-size: 2.8em;
  }
}

#blog-page .container .blog-list .blog h2.title a {
  color: #a7a7a7;
  font-weight: 700;
}

#blog-page .container .blog-list .blog .share {
  position: absolute;
  bottom: 25px;
  right: 25px;
}

.blog-pagination {
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.blog-pagination a,
.blog-pagination span {
  display: inline-block;
  font-size: 1.2em;
  padding: 10px;
  background: white;
  -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  margin: 3px;
  color: #555;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.blog-pagination a:hover,
.blog-pagination span:hover {
  background: #555;
  color: white;
}

.blog-pagination span.current {
  background: #555;
  color: white;
}

#blog-page-display .blog {
  max-width: 700px;
}

#blog-page-display .blog .blog-image img {
  width: 100%;
  max-width: 100%;
}

#blog-page .blog-list {
  width: 630px;
  max-width: 100%;
  margin-bottom: 95px;
}

#blog-page .blog-list .blog,
#blog-page-display .blog-list .blog {
 
  margin-top: 60px;
  border-bottom: 1px solid #cccccc;
}

#blog-page-display .blog-list .blog {
  margin-bottom: 60px;
  text-align: left;

  h1.title {
    font-family: $itc-gothic;
    font-style: normal;
    font-weight: normal;
    font-size: 35px;
    line-height: 59px;
    letter-spacing: 0.5px;
    color: $heading-color;
    @media all and (max-width: $tablet-width) {
      font-size: 28px;
      line-height: 36px;
    }
  }
}

#blog-page .blog-list .blog .head,
#blog-page-display .blog-list .blog .head {
  margin-bottom: 15px;
  // padding-top: 20px;
  @media all and (min-width:$large-desktop-width){
    padding-top: 30px;
  }
}
#blog-page .blog-list .blog .head span,
#blog-page-display .blog-list .blog .head span {
  display: inline-block;
  font-weight: normal;
  font-size: 1.2em;
  font-style: italic;
  line-height: 1.2em;
  color: #b8b8b8;
}
#blog-page .blog-list .blog h2,
#blog-page-display .blog-list .blog h2 {
  font-weight: 700;
  font-size: 30px;
  color: $heading-color;
  margin: 0;
  margin-bottom: 15px;
  letter-spacing: 0;
}
#blog-page .blog-list .blog .blog-image,
#blog-page-display .blog-list .blog .blog-image {
  margin-bottom: 15px;
}
#blog-page .blog-list .blog .blog-image img,
#blog-page-display .blog-list .blog .blog-image img {
  max-width: 100%;
}
#blog-page .blog-list .blog .content p,
#blog-page-display .blog-list .blog .content p {
  font-weight: 500;
  font-size: 1.3em;
  color: #989797;
  margin: 0;
  margin-bottom: 15px;
  text-align: justify;
}
#blog-page .blog-list .blog .content a,
#blog-page-display .blog-list .blog .content a {
  color: #989797;
  font-weight: normal;
  font-size: 12px;
  transition: all 0.3s ease;
}
#blog-page .blog-list .blog .content a:hover,
#blog-page-display .blog-list .blog .content a:hover {
  color: #727171;
}
#blog-page .blog-list .blog .more a,
#blog-page-display .blog-list .blog .more a {
  color: #989797;
  font-weight: normal;
  font-size: 12px;
  transition: all 0.3s ease;
}
#blog-page .blog-list .blog .more a:hover,
#blog-page-display .blog-list .blog .more a:hover {
  color: #727171;
}
#blog-page .blog-list .blog .share p,
#blog-page-display .blog-list .blog .share p {
  font-family: Arial, sans-serif;
  color: #b8b8b8;
  font-size: 1.1em;
  line-height: 1.6em;
  margin-bottom: 0.5em;
  text-align: right;
  max-width: 100%;
}
#blog-page .blog-list .blog .share a,
#blog-page-display .blog-list .blog .share a {
  display: inline-block;
  margin-right: 5px;
  vertical-align: bottom;
}
#blog-page .blog-list .blog .share a.twitter,
#blog-page-display .blog-list .blog .share a.twitter {
  margin-left: 10px;
}

#blog-page .blog-list .blog .share i,
#blog-page-display .blog-list .blog .share i {
  color: #d4d4d4;
  font-size: 2.5em;
}

#blog-page.blog-content,
#blog-page-display.blog-content {
  padding-top: 100px;
}

@media all and (max-width: 1015px) {
  #blog-page.blog-content,
  #blog-page-display.blog-content {
    padding-top: 30px;
  }
}

.sticker-ad {
  display: none;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #272727;

  a.btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 2.5em;
    color: white;
    line-height: 0em;
    width: 25px;
    height: 25px;
    padding: 10px 5px;
    cursor: pointer;
    text-decoration: none;
    transform: none;
    background: none;

    &:hover {
      opacity: 0.8;
    }
  }

  .sticker-ad-container {
    padding: 20px;
    text-align: center;
    max-width: 1120px;
    margin: auto;

    h3 {
      margin: 0 0 10px;
      color: white;
      font-size: 1.6em;
      font-weight: bold;
    }

    p {
      font-size: 1.2em;
      color: white;
      font-weight: normal;
    }

    a {
      display: inline-block;
      font-size: 1em;
      color: white;
      border: 1px solid white;
      padding: 5px 10px;
      transition: all 0.3s ease;
      text-decoration: none;

      &:hover {
        background-color: white;
        color: #272727;
        text-decoration: none;
      }
    }
  }

  @media all and (max-width: $mobile-width) {
    .sticker-ad-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 15px 50px 15px 15px;

      h3 {
        margin: 0 10px 0 0;
        font-size: 1.4em;
      }

      p {
        display: none;
      }
    }

    a.btn-close {
      top: 17px;
      right: 10px;
    }
  }
}

#search {
  .blog {
    box-shadow: 0 0 8px #b3b3b3 !important;
  }
}

#search-result {
  padding-bottom: 50px;

  & > div {
    background: white;
    padding: 50px 0 20px !important;
  }

  h3.search-section {
    text-align: left;
    margin-bottom: 0;
  }

  .facades-container {
    padding-bottom: 0;
  }

  .display-home-locations {
    padding: 0;
  }

  #house-and-land-packages-horizontal {
    padding: 0;
  }

  .facade {
    .details {
      border-left: 1px solid #e2e2e2;
      border-right: 1px solid #e2e2e2;
      border-bottom: 1px solid #e2e2e2;
    }
  }
}

// #wpadminbar {
//   position: fixed;
//   top: 0;
//   z-index: 99;
//   height: 32px;
//   width: 100%;
//   background-color: red;

//   @media all and (max-width: 782px) {
//     height: 46px;
//   }
// }

.new-find-floor-section {
  text-align: center;
  @media all and (max-width: 640px) {

  }
  .new-find-floor-section-content {
    padding: 50px 15px;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    a.btn-orange {
      width: auto;
      font-size: 16px;
      line-height: 45px;
      padding: 0 30px;
    }
  }
  .banner-content {
    color: #FFF;
    font-family: $roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    p {
      @media all and (max-width: 500px) {
        font-size: 14px !important;
      }
    }
    strong {
      font-family: $itc-gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 29px;
      line-height: 39px;
      letter-spacing: 0;
      color: #ffa018;
      @media all and (max-width: $large-desktop-width) {
        font-size: 35px;
        line-height: 39px;
        margin-bottom: 20px;
      }
      @media all and (max-width: $tablet-width) {
        width: 100%;
        display: block;
      }
    }
  }
}

/* New CSS ETS 28-07-2021 */
.secret_choosing_builder {  
  background: #ffa018;
  .choos-builder{
    // background: url(../img/option2.jpg) linear-gradient(269deg, rgba(0 0 0 / 50%) 100%, rgba(0 0 0 / 65%) 33%, rgba(249, 249, 249, 0) 100%);
    background-repeat: no-repeat;
    background-size: cover;
  }
 
  .banner-content {
    color: #FFF;
    font-family: $roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 26px;
    strong {
      font-family: $itc-gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 29px;
      line-height: 39px;
      letter-spacing: 0;
      color: #FFF;
      margin-right: 20px;
      @media all and (max-width: $large-desktop-width) {
        font-size: 35px;
        line-height: 39px;
        margin-bottom: 20px;
      }
      @media all and (max-width: $tablet-width) {
        width: 100%;
        display: block;
      }
    }
  }

  .secret_choosing_builder_content {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 5%;
    padding-right: 5%;
    display: table;
    width: 100%;
    text-align: center;
    
    .banner-content {
      display: table-cell;
      vertical-align: top;
      // vertical-align: middle;
      text-align: center;
      padding: 20px 0;

      a.btn-orange {
        width: auto;
        font-size: 16px;
        line-height: 45px;
        padding: 0 30px;
      }
      @media all and (max-width: $tablet-width) {
        padding: 30px 0;
      }
      
      h2 {
        font-family: $itc-gothic;
        font-style: normal;
        font-weight: normal;
        font-size: 35px;
        line-height: 39px;
        letter-spacing: 0;
        color: #fff;
        font-weight: 600;
        @media all and (max-width: $tablet-width) {
          
        }
      }
      p {
        margin: 0;
        font-family: $roboto;
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        color: #fff;

       
        @media all and (max-width: $tablet-width) {
          margin: 30px auto;
        }
      }
      @media all and (max-width: $tablet-width) {
        width: 100%;
        text-align: center;
      }
      button.btn-orange {
        font-family: $roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 30px;
        color: #FFF;
        padding: 14px 30px;
        border-radius: 0;
        border: 1px solid transparent;
  
        &:focus,
        &:hover {
          color: #ffa018;
          background-color: #fff;
          border: 1px solid #ffa018;
        }
      }
    }
  }
}

.filter-search{
  text-align: left;
  padding-bottom: 30px;
}

.footer-contact {
    padding: 100px 0;
    display: inline-block;
    width: 100%;
    @media all and (max-width: $tablet-width) {
      padding: 30px 0;
    }
  .form_right_section,
  .content_left_section {
    float: left;
    width: 50%;
    h2 {
      font-family: $itc-gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 35px;
      line-height: 39px;
      letter-spacing: 0;
      color: #000;
      @media all and (max-width: $tablet-width) {
        
      }
    }
    p {
      font-family: $roboto;
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      color: #000;
      max-width: 485px;
      @media all and (max-width: $tablet-width) {
        margin: 30px auto;
      }
    }
    @media all and (max-width: $tablet-width) {
      width: 100%;
      text-align: center;
    }
  }

  .form_right_section .basic-form input,
  .form_right_section .basic-form select {
    background-color: #F0EEEB;
    border: none;
    border-radius: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    color: #000;
    font-family: $roboto;
    padding: 14px;
    height: auto;
  }

  .form_right_section {

    button.btn-orange {
      font-family: $roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 30px;
      color: #FFF;
      padding: 14px 30px;
      border-radius: 0;
      border: 1px solid transparent;

      &:focus,
      &:hover {
        color: #ffa018;
        background-color: #fff;
        border: 1px solid #ffa018;
      }
    }
    @media all and (max-width: $tablet-width) {
      width: 100%;
      text-align: center;
    }
  }

  input::placeholder,
  select::placeholder {
    opacity: 1;
  }
}

.home-dev-swiper-container .swiper-slide {
  margin: 0;
}

.home-dev-floor-plan-facades {

  .dev-house-and-land-content {
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 90%);
    display: inline-block;
    width: 100%;
    position: absolute;
    bottom: 165px;
    left: 0;

    h4 {
      margin-bottom: 20px;
      color: #FFF;
      display: inline-block;
      padding: 0 20px;
      font-family: $roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 26px;
      @media all and (max-width: 320px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    .house-and-land-amenities {
      float: right;
      color: #FFF;
      width: 44%;
      .amenities-icons {
        display: inline-block;
        width: 32%;
        img {
          display: inline-block;
          margin-right: 15px;
        }
        @media  all and (max-width:767px) {
            // width: 100px;
            // height: 500px;

        }
        p {
          font-family: $roboto;
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          font-size: 19px;
          line-height: 25px;
          color: #FFF;
          margin-bottom: 0;
          position: relative;
          top: 3px;
        }
      }
    @media all and (max-width: $tablet-width) {
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
        // color: $heading-color
      }
    }
    @media all and (max-width: $tablet-width) {
      bottom: 236px;
      h4 {
          width: 100%;
          text-align: center;
          margin-top: 10px;
          padding: 0;
       }
    }
    @media all and (max-width: 850px) {
      bottom: 250px;
    }
    @media all and (max-width: $small-mobile-width) {
      
    }

    @media all and (max-width: 330px) {
      bottom: 223px;
    }
   
  }

  .after-dev-house-and-land-content {
    background-color: #fff;
    
    .dev-house-details {
      float: left;
      width: 60%;
      padding: 10px 20px 0;
      span,
      p {
        color: $heading-color;
        font-size: 14px;
        line-height: normal;
        margin: 10px 0;
        &:first-child {
          @media all and (max-width: 850px) {
            min-height: 30px;
          }
        }
      }
      @media all and (max-width: $tablet-width) {
        float: none;
        text-align: center;
        padding: 20px 0 0;
        width: 100%;
      }
    }

    .dev-house-price {
      float: right;
      width: 25%;
      padding: 0 20px;
      text-align: right;
      margin-top: 30px;
      h6 {
        color: #ffa018;
        font-size: 28px;
        line-height: normal;
        margin: 10px 0;
      }
      @media all and (max-width: $tablet-width) {
        float: none;
        text-align: center;
        width: 100%;
		    margin-top: 20px;
      }
    }

    .dev-house-links {
      clear: both;
      text-align: center;
      a {
        font-size: 1.5em;
        padding: 12px 30px;
        margin: 10px 10px 20px;
        border: 1px solid transparent;
        @media all and (max-width: $tablet-width) {
          margin: 10px 10px 30px 10px;
        }
        @media all and (max-width: $super-small-mobile-width) {
          
        }
        @media all and (max-width: 330px) {
          margin-bottom: 20px;
          font-size: 12px;
        }
        &:hover,
        &:focus {
          background-color: #fff;
          color: #ffa018;
          border: 1px solid #ffa018;
        }
      }
    }
  }

  p.home-land-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $heading-color;
    font-size: 1.5em;
    text-align: center;
    margin: 5px 0 0 0;
    font-weight: 700;
    .btn-next i,
    .btn-prev i {
      color: $heading-color;
      font-size: 2.8em;
      padding: 10px;
    }
  }
}

#contact-us,
#testimonial,
#choose_build,
#promotion_deals,
#where_build,
#why-build-with-aston-homes,
#display-home {
  .basic-banner.hero-banner {
    h1 {
      font-family: $itc-gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 35px;
      line-height: 59px;
      letter-spacing: 0;
      color: $heading-color;
      @media all and (max-width: $tablet-width) {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
}

#blog {
  .hero-banner {
    h1 {
      font-family: $itc-gothic;
      font-style: normal;
      font-weight: normal;
      font-size: 35px;
      line-height: 59px;
      letter-spacing: 0;
      color: $heading-color;
      @media all and (max-width: $tablet-width) {
        font-size: 28px;
        line-height: 36px;
      }
    }
  }
}

.dev-search-locations {
  font-family: $itc-gothic;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0;
  color: #ffa018;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

#footer {
  .before_logos_section {
    display: block;
    clear: both;
    width: 100%;
    .footer-logo-container {
      float: left;
      width: 185px;
      @media all and (max-width: $tablet-width) {
        float: none;
        margin: 0 auto;
      }
    }
    .right {
      float: right;
      width: 70%;
      text-align: right;
      @media all and (max-width: $tablet-width) {
        float: none;
        width: 100%;
        text-align: center;
        clear: both;
      }
      a {
        display: inline-block !important;
        i {
          font-size: 24px;
          width: 40px;
          height: 40px;
          text-align: center;
          background-color: #fff;
          border-radius: 100%;
          line-height: 40px;
          color: #323232;
          margin: 30px 6px;
          &:hover,
          &:focus {
            background-color: #000;
            color: #fff;
          }
        }
      }
    }
  }
}

@media all and (max-width: $tablet-width) {
  .main .hero.hero-inclusion .hero-image {
    background-size: auto 152px !important;
    background-position: top right !important;
    background-repeat: no-repeat !important;
  }
}

#floor-plan-result {
  .alldropdown {
    width: 20%;
    margin: 0 4px;
   
    @media all and (max-width: 1110px) {
      width: 49%;
      display: inline-block;
      padding: 1%;
      margin: 0;
    }
  
    @media all and (max-width: 450px) {
      width: 100%;
      display: inline-block;
      padding: 0;
    }
    label {
        color: #494948;
        font-weight:700;
    }
  }
  .car-garage{
    @media all and (max-width: 1110px) {
      width: 100% !important;
    }
    @media all and (max-width: 426px) {
      width: 100% !important;
  }
  .flex-items{
    @media  (min-width: 1025px) and (max-width: 1110px)  {
      margin-right: 14px;
   }
//    @media all and (max-width: 768px) {
//     margin-right: 0px;
// }
// @media all and (max-width: 425px) {
//   margin-right: 0;
// }
  }
}
  @media all and (max-width: $small-mobile-width) {
    .floor-plan.desktop-floor-plan {
      display: none;
    }
  }
    .floor-plan.mobile-floor-plan {
      display: none;
      @media all and (max-width: $small-mobile-width) {
        display: block;
        text-align: center;
        padding: 0;

          .floor_aminities_details {
            border: 2px dotted #d4d4d4;
            display: inline-block;
            border-right: none;
            padding-bottom: 20px !important;
            border-left: none;
            padding-top: 20px !important;
          }

          .floor-plan-header {
            justify-content: center;
            border-bottom: none;
            .floor-plan-title {
              display: block;
              width: 100%;
              .house-rooms {
                justify-content: center;
              }
              h3 {
                padding-bottom: 10px;
                clear: both;
                display: block;
                margin: 0 auto;
                width: 100%;
              }
            }
          }
          .dev-floor-details .col-sm-6 {
            width: 50%;
            float: left;
            border-left: 2px dotted #d4d4d4;
            min-height: 360px;
            .floor-plan-image {
              padding: 0;
              display: block;
              img{
                width: 100%;
              }
              a{
                img{
                  width: auto
                }
              }
            }

          }
          .floor-plan-details {
            border-left: 0 dotted #d4d4d4 !important;
          }
          .mobile-plan-content {
            display: inline-block;
            width: 100%;
          }
          
          .download_buttons_section {
            clear: both;
            padding-top: 20px !important;
            padding-bottom: 20px !important;
            border-bottom: 2px dotted #d4d4d4;
            display: inline-block;
            .col-sm-6 {
              width: 50%;
              float: left; 
              @media all and (max-width: 375px) {
                display: inline-block;
              }
            }
          }

          .container.mobile-slider-images {
              clear: both;
              width: 100%;
              text-align: center;
              @media all and (max-width: $small-mobile-width) {
                padding-bottom: 0px !important;
            }
              .floor-plan-facades-container {
                margin: 0 auto;
              }
          }
        }
  }
}

.privacy-content{
  table{
      tbody{
        vertical-align: top;
      }
  }
}

#bottom_cotents_section h3 {
    font-family: "ITC Avant Garde Gothic";
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: 0;
    color: #000;
    margin-bottom: 10px;
}

#bottom_cotents_section h2{
  text-align: center;
  color: #333132;
  margin: 0 0 20px;
  @media all and (max-width: 768px) {
    font-size: 30px;
    line-height: 40px;
  }
}

#bottom_cotents_section h4,
#bottom_cotents_section li,
#bottom_cotents_section p {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #000;
}

#bottom_cotents_section h4 {
  font-weight: 400;
}

.aston-loader{
  text-align: center;
  background: transparent !important;
  padding-bottom: 80px;
  @media all and (max-width:768px){
    padding-bottom: 40px;
  }
}

@media all and (max-width:1110px) {
  #floor-plan-result{
    .filter-wrap {
      display: block;
    }
  }
}
.consultant-name-email {
  display: block;
  width: 0px;
  height: 0px;
  margin: 0 !important;
  input {
    border: none;
    width: 0px;
    height: 0px;
  }
}

#house-and-land-packages-horizontal{
  .house-and-land-container{
    .house-and-land-content{
      .left{
        h3{
          font-weight: 600;
          color: #494948;
        }
      }
    }
  }
}