#hamburger {
  display: none;
  height: 18px;
  width: 24px;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;

  &.active {
    .top-bar {
      transform: translateY(7px) translateX(0) rotate(45deg);
    }

    .mid-bar {
      opacity: 0;
    }

    .bot-bar {
      transform: translateY(-7px) translateX(0) rotate(-45deg);
    }
  }

  span {
    background: #e7c6c7;
    border: none;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;
    padding: 0;

    &:nth-of-type(2) {
      top: 7px;
    }

    &:nth-of-type(3) {
      top: 14px;
    }
  }
}
