$large-desktop-width: 1440px;
$desktop-width: 1280px;
$tablet-width: 1080px;
$ipad-width:1024px;
$mobile-width: 768px;
$small-mobile-width: 500px;
$super-small-mobile-width: 430px;

$body-background-color: #ffffff;
$main-color:#ffa018;
$essential-color: #52b113;
$heading-color: #333132;
$text-color: #494948;

$footer-bg-color: #323232;
$footer-text-color: #cececd;
$footer-text-white-color: #fdfdfd;
$footer-text-gray-color: #707070;

$banner-bg-color: #333132;
$banner-btn-color: #070707;

$display-text-color: #565555;
$display-text-bold-color: #1d1d1d;
$gray-bg-color: #f0eeec;
$basic-text-color: #808285;

$accordion-border-color: #e1e1e0;
$accordion-bg-color: #e5e2e0;
$accordion-text-color: $footer-text-gray-color;

$form-input-bg-color: #f5f4f3;
$form-input-border-color: #d5d5d5;
$form-input-text-color: #000;

$contact-heading-color: #434343;

$enquiry-bg-color: #f3f3f3;
$display-home-bg: #fef5e7;
$house-land-bg: #f9f7f5;
$house-land-border: #e6e4e2;
$hero-heading-color: #494948;
$hero-text-color: #353434;

@mixin font-main {
  font-family: "Gotham", sans-serif;
}

@mixin font-kremlin {
  font-family: kremlin-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin font-roboto {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@mixin font-itc-gothic {
  font-family: "ITC Avant Garde Gothic";
  font-style: normal;
}

@mixin placeholder($value) {
  &::-webkit-input-placeholder {
    color: $value;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $value;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $value;
  }

  &:-ms-input-placeholder {
    color: $value;
  }
}

body {
  font-size: 10px;
  background: $body-background-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
form,
input,
select,
li,
button {
  @include font-main();
  color: $text-color;
  font-weight: normal;
  outline: none;
}

* {
  -webkit-tap-highlight-color: transparent;
}

p {
  font-size: 1.2em;
}

a,
button {
  transition: all 0.3s ease;
}

h6 {
  font-size: 1.5em;
  font-weight: normal;
  color: $main-color;
  line-height: 1em;
  margin: 0 0 10px;
}

@mixin heading-5em() {
  letter-spacing: -2px;
  font-weight: 300;
  font-size: 5em;
  margin: 0 0 30px;
  line-height: 1em;

  @media all and (max-width: $tablet-width) {
    font-size: 4.5em;
    margin: 0 0 25px;
  }

  @media all and (max-width: $mobile-width) {
    font-size: 4em;
  }

  @media all and (max-width: $small-mobile-width) {
    font-size: 3.5em;
  }
}

@mixin heading-3em() {
  font-size: 3em;
  font-weight: 300;
  color: $main-color;
  letter-spacing: -1px;
  margin: 0 0 25px;

  @media all and (max-width: $tablet-width) {
    font-size: 2.5em;
  }

  @media all and (max-width: $mobile-width) {
    font-size: 2em;
    letter-spacing: 0px;
  }
}

h3 {
  @include heading-3em();
  line-height: 1em;
}
